// components
import {
  Abc,
  CheckBox,
  ToggleOn,
  ArrowDropDownCircle,
  RadioButtonChecked,
  StarHalf,
  CalendarToday,
  HorizontalRule,
  ArrowLeft,
  ArrowDropUp,
  ArrowRight,
  ArrowDropDown,
  MoreHoriz,
  MoreVert,
  FormatAlignLeft,
  FormatAlignCenter,
  FormatAlignRight,
  Circle,
  Grade,
  SquareRounded,
  CheckBoxOutlineBlank,
  Splitscreen,
  OpenInNew,
  Window,
} from '@mui/icons-material'

// types
import { OptionsButtonVariant } from '../types/redux/appearance'
import {
  InputType,
  StyleType,
  Alignment,
  LabelPlacement,
  Direction,
  TextAlignment,
  MuiColor,
  RatingPrecision,
} from '../types/redux/layout'

//
// layout
//

export const inputTypes = [
  'Textfield',
  'Checkbox',
  'Datepicker',
  'Dropdown',
  'Radiogroup',
  'Rating',
  'Switch',
] as const

export const inputTypeIcons: { [key in InputType]: JSX.Element } = {
  Textfield: <Abc />,
  Checkbox: <CheckBox />,
  Switch: <ToggleOn />,
  Dropdown: <ArrowDropDownCircle />,
  Radiogroup: <RadioButtonChecked />,
  Rating: <StarHalf />,
  Datepicker: <CalendarToday />,
}

export const styleTypes = ['Text', 'Divider'] as const
export const styleTypeIcons: {
  [type in StyleType]: JSX.Element
} = {
  Text: <Abc />,
  Divider: <HorizontalRule />,
}

export const alignments = ['left', 'top', 'right', 'bottom'] as const
export const alignmentIcons: { [key in Alignment]: JSX.Element } = {
  left: <ArrowLeft />,
  top: <ArrowDropUp />,
  right: <ArrowRight />,
  bottom: <ArrowDropDown />,
}

export const labelPlacements = ['start', 'top', 'end', 'bottom'] as const
export const labelPlacementIcons: { [key in LabelPlacement]: JSX.Element } = {
  start: <ArrowLeft />,
  top: <ArrowDropUp />,
  end: <ArrowRight />,
  bottom: <ArrowDropDown />,
}

export const directions = ['row', 'column'] as const
export const directionIcons: { [key in Direction]: JSX.Element } = {
  row: <MoreHoriz />,
  column: <MoreVert />,
}

export const muiColors = [
  'primary',
  'secondary',
  'error',
  'warning',
  'info',
  'success',
] as const
export const muiColorIcons: { [key in MuiColor]: JSX.Element } = {
  primary: <Circle color='primary' />,
  secondary: <Circle color='secondary' />,
  error: <Circle color='error' />,
  warning: <Circle color='warning' />,
  info: <Circle color='info' />,
  success: <Circle color='success' />,
}

export const ratingPrecisions = [0.5, 1] as const
export const ratingPrecisionIcons: { [key in RatingPrecision]: JSX.Element } = {
  0.5: <StarHalf />,
  1: <Grade />,
}

export const textTypes = ['heading', 'subtitle', 'body'] as const

export const textAlignments = ['left', 'center', 'right'] as const
export const textAlignmentIcons: { [key in TextAlignment]: JSX.Element } = {
  left: <FormatAlignLeft />,
  center: <FormatAlignCenter />,
  right: <FormatAlignRight />,
}

//
// appearance
//
export const muiButtonVariants = ['contained', 'outlined', 'text'] as const
export const optionIconsButtonVariant = (variant: OptionsButtonVariant) => {
  switch (variant) {
    case OptionsButtonVariant.contained:
      return <SquareRounded sx={{ marginRight: 1 }} />
    case OptionsButtonVariant.outlined:
      return <CheckBoxOutlineBlank sx={{ marginRight: 1 }} />
    case OptionsButtonVariant.text:
      return <Abc sx={{ marginRight: 1 }} />
  }
}
export const optionIconsButtonLayout = (grouped: boolean) =>
  grouped ? (
    <SquareRounded sx={{ marginRight: 1 }} />
  ) : (
    <Splitscreen sx={{ marginRight: 1 }} />
  )

export const optionIconsFormLocation = (popup: boolean) =>
  popup ? (
    <OpenInNew sx={{ marginRight: 1 }} />
  ) : (
    <Window sx={{ marginRight: 1 }} />
  )
