// types
import { DataTable, DataValue, Worksheet } from '@tableau/extensions-api-types'
import { SupportedLocales } from 'src/types/redux/appearance'

// assets
import { localeOptions } from 'src/redux/appearance'

const tableauFunctions = [
  'MIN',
  'MAX',
  'MAX.',
  'ANZ',
  'ANZE',
  'ATTR',
  'VARP',
  'SUM',
  'MEDIAN',
  'DURSCHN',
  'STDABW',
  'STDABWP',
  'VAR',
]

export const getLocale = () => {
  console.log("get locale")
  let loc = tableau.extensions?.environment?.locale
  let locale: SupportedLocales = 'enUS'
  if (loc) {
    loc = loc.replace('-', '')
    let reg = loc.slice(2, 4)
    loc = loc.slice(0, 2) + reg.toUpperCase()
    if (localeOptions.indexOf(loc) > -1) locale = loc as SupportedLocales
    else locale = 'enUS' as SupportedLocales
  }
  return locale
}

export const createPossibleTableauDimensions = (key: string) =>
  tableauFunctions.map((f) => `${f}(${key})`)

export const findColIdx = ({
  dataTable,
  key,
}: {
  dataTable: DataTable
  key: string
}) => {
  const index = dataTable.columns.find(
    (col) =>
      col.fieldName.slice(5, 5+key.length) === key || // ATTR(...)
      col.fieldName.slice(0, key.length) === key ||
      col.fieldId.slice(-key.length - 1, -1) === key ||
      col.fieldName.startsWith(key)
  )?.index
  return typeof index === 'number' ? index : -1
}

export const findColumnIndex = ({
  dataTable,
  key,
}: {
  dataTable: DataTable
  key: string
}) => {
  const index = dataTable.columns.find(
    (col) =>
      col.fieldName.slice(5, 5+key.length) === key || // ATTR(...)
      col.fieldName.slice(0, key.length) === key ||
      col.fieldId.slice(-key.length - 1, -1) === key ||
      col.fieldName.startsWith(key)
  )?.index
  return typeof index === 'number' ? index : -1
}

export const findRow = ({
  dataTable,
  key,
  val,
}: {
  dataTable: DataTable
  key: string
  val: number | string | boolean
}) => {
  const colIdx = findColIdx({ dataTable, key })
  return typeof colIdx === 'number'
    ? dataTable.data.find((row) => row[colIdx].nativeValue === val)
    : undefined
}

export const findRows = ({
  dataTable,
  key,
  val,
}: {
  dataTable: DataTable
  key: string
  val: number | string | boolean
}) => {
  const colIdx = findColIdx({ dataTable, key })
  return colIdx
    ? dataTable.data.filter((row) => row[colIdx].nativeValue === val)
    : undefined
}

export const getValFromRow = ({
  row,
  colIdx,
}: {
  row: DataValue[]
  colIdx: number
}) => row[colIdx].nativeValue

export const getCbDataTable = async ({
  configName,
  commentWorksheet,
}: {
  configName: string
  commentWorksheet: Worksheet | undefined
}) => {

  if(commentWorksheet)
  {
    return commentWorksheet.getDataSourcesAsync().then(async (dss) => {
      
      //
      // Get the datasource that contains the comment uid
      //
      
      const dsFilter = await Promise.all(
        dss.map((ds) =>
          ds.fields.find(
            (field) => field.id.startsWith('Comment Uid')
          )
        )
      )
      const dataSources = dss.filter((_, i) => dsFilter[i])

      console.log("data sources after filter:", dataSources);

      // throw error if multiple are found ?
      if (dataSources.length > 1)
      {
        console.log(
          `more than one data source containing the cb_comments_${configName} have been found`
        )
      }

      //
      // Get the logical table that contains the comment data
      //

      if (dataSources.length > 0)
      {
        return dataSources[0].getLogicalTablesAsync().then(async (lts) => {
          
          //const dataTable : DataTable | undefined = await;
          
          const logicalTables = await Promise.all(lts.map(async (lt) => {
            return dataSources[0].getLogicalTableDataAsync(lt.id).then((ltd) => ltd);
          }));
          
          const dataTable = logicalTables.find((ltd) =>
          {
            if(ltd)
            {
              const index = findColIdx({ dataTable: ltd, key: 'Comment Uid' });
              if(index >= 0) return true;
              return false;
            }
          });
          
          if (dataTable)
          {
            return dataTable;
          }
          else
          {
            return new Promise<undefined>((resolve) => resolve(undefined))
          }
        })
      }
    })
  } else return new Promise<undefined>((resolve) => resolve(undefined))
}

export const getCbDataTableReader = async ({
  configName,
  commentWorksheet,
  columnsToInclude,
}: {
  configName: string
  commentWorksheet: Worksheet | undefined
  columnsToInclude: Array<string>
}) => {

  if(commentWorksheet)
  {
    return commentWorksheet.getDataSourcesAsync().then(async (dss) => {
      
      //
      // Get the datasource that contains the comment uid
      //
      
      const dsFilter = await Promise.all(
        dss.map((ds) =>
          ds.fields.find(
            (field) => field.id.startsWith('Comment Uid')
          )
        )
      )
      const dataSources = dss.filter((_, i) => dsFilter[i])

      console.log("data sources after filter:", dataSources);

      // throw error if multiple are found ?
      if (dataSources.length > 1)
      {
        console.log(
          `more than one data source containing the cb_comments_${configName} have been found`
        )
      }

      //
      // Get the logical table that contains the comment data
      //

      if (dataSources.length > 0)
      {
        return dataSources[0].getLogicalTablesAsync().then(async (lts) => {
          
          //const dataTable : DataTable | undefined = await;
          
          const logicalTables = await Promise.all(lts.map(async (lt) => {
            return dataSources[0].getLogicalTableDataAsync(lt.id, {maxRows: 1}).then((ltd) =>
            {
              return {dataTable: ltd, id: lt.id}
            });
          }));
          
          const dataTable = logicalTables.find((ltd) =>
          {
            if(ltd)
            {
              const index = findColIdx({ dataTable: ltd.dataTable, key: 'Comment Uid' });
              if(index >= 0) return true;
              return false;
            }
          });
          
          if (dataTable)
          {
            console.log("found comment data table:", dataTable);
            // TODO: @Perf: Returns all columns and not just the ones in columnsToInclude -> api bug?
            const dataTableReader = await dataSources[0].getLogicalTableDataReaderAsync(dataTable.id, 10000,
              {
                includeDataValuesOption: tableau.IncludeDataValuesOption.OnlyNativeValues,
                columnsToInclude: columnsToInclude
              });
            return dataTableReader;
          }
          else
          {
            return new Promise<undefined>((resolve) => resolve(undefined))
          }
        })
      }
    })
  } else return new Promise<undefined>((resolve) => resolve(undefined))
}

export const getCbSummaryDataTable = async ({
  configName,
  commentWorksheet,
}: {
  configName: string
  commentWorksheet: Worksheet
}) => {
  console.log("Version:", tableau.extensions.environment.tableauVersion);
  const tableauVersion = tableau.extensions.environment.tableauVersion//.split('.');
  if(tableauVersion.localeCompare("2022.4.3", undefined, { numeric: true, sensitivity: 'base' }) < 0)
  {
    return commentWorksheet.getSummaryDataAsync({ignoreSelection: true});
  }
  const dataTableReader = await commentWorksheet.getSummaryDataReaderAsync(undefined, {ignoreSelection: true});
  const dataTable = await dataTableReader.getAllPagesAsync();
  await dataTableReader.releaseAsync();
  return dataTable;
}
