// hooks
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { useLanguage } from 'src/hooks/languages'

// mui
import {
  Stack,
  Divider,
  AccordionDetails,
  TextField,
  FormControlLabel,
  Checkbox,
  Tooltip,
  Typography,
} from '@mui/material'

// components
import CheckboxSwitchSettings from './CheckboxSwitch'
import DatepickerElementSettings from './Datepicker'
import DropdownElementSettings from './Dropdown'
import RatingElementSettings from './Rating'
import TextfieldElementSettings from './Textfield'
import RadiogroupSettings from './Radio'
import DependsOn from '../dependsOn'
import DropdownSelect from '../../DropdownSelect'

// types
import { ChangeEvent, FC } from 'react'
import { InputElement, InputType } from '../../../../types/redux/layout'

// assets
import { textFieldStyleProps } from '../../../../assets/helpers/mui'
import { inputTypeIcons } from '../../../../assets/settings'
import {
  maxTableNameLength,
  restrictTableNameChars,
} from '../../../../assets/databases'

// actions
import { layoutActions } from '../../../../redux/layout'

interface InputElementSettingsProps {
  idx: number
}

const InputElementSettings: FC<InputElementSettingsProps> = ({
  idx,
}): JSX.Element => {
  const texts = useLanguage()

  const dispatch = useAppDispatch()

  const element = useAppSelector((state) => state.layout[idx] as InputElement)
  const published = useAppSelector(
    (state) =>
      (state.config.layout &&
        state.config.layout.findIndex(
          (el) =>
            'dbCol' in el &&
            el.dbCol === (state.layout[idx] as InputElement).dbCol &&
            !element.configErrors.dbColDuplicate
        ) !== -1) ||
      false
  )

  const handleUpdateInput = (options: Partial<InputElement>) =>
    dispatch(layoutActions.updateInput({ idx, options }))

  const handleChangeInputType = (event: ChangeEvent<HTMLInputElement>) =>
    dispatch(
      layoutActions.updateInputType({
        idx,
        type: event.target.value as InputType,
      })
    )

  // get the corret element specifix settings
  const props = { idx, handleUpdateInput }
  let settings: JSX.Element
  switch (element.type) {
    case 'Datepicker':
      settings = <DatepickerElementSettings {...props} />
      break
    case 'Dropdown':
      settings = <DropdownElementSettings {...props} />
      break
    case 'Checkbox':
    case 'Switch':
      settings = <CheckboxSwitchSettings {...props} />
      break
    case 'Radiogroup':
      settings = <RadiogroupSettings {...props} />
      break
    case 'Rating':
      settings = <RatingElementSettings {...props} />
      break
    case 'Textfield':
      settings = <TextfieldElementSettings {...props} />
      break
  }

  return (
    <AccordionDetails>
      <Stack direction='column' width='stretch' spacing={2}>
        <Stack direction='row' spacing={2}>
          <DropdownSelect
            {...{
              label: texts.inputLabelInputElementType,
              iconMatches: inputTypeIcons,
              value: element.type,
              onChange: handleChangeInputType,
            }}
          />
          <Tooltip
            title={
              (published && (
                <Typography variant='body2'>
                  {texts.tooltipDatabaseIdentifierImmutablePostSave}
                </Typography>
              )) || <></>
            }
          >
            <TextField
              {...textFieldStyleProps(texts.inputLabelDatabaseColumnName)}
              error={
                element.configErrors.dbColMissing ||
                element.configErrors.dbColDuplicate
              }
              helperText={
                (element.configErrors.dbColMissing &&
                  texts.formHelperDatabaseColMissing) ||
                (element.configErrors.dbColDuplicate &&
                  texts.formHelperDatabaseColDuplicate)
              }
              disabled={published}
              inputProps={{
                maxLength: maxTableNameLength(),
                onKeyDown: (event) => restrictTableNameChars(event),
              }}
              value={element.dbCol}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                dispatch(
                  layoutActions.setDbCol({ idx, dbCol: event.target.value })
                )
              }
            />
          </Tooltip>
        </Stack>
        <Stack direction='row' spacing={2}>
          <TextField
            {...textFieldStyleProps(texts.inputLabelInputLabel)}
            value={element.label}
            onChange={(event) =>
              handleUpdateInput({
                label: event.target.value,
              })
            }
          />
        </Stack>
        <FormControlLabel
          control={
            <Checkbox
              checked={element.required}
              onChange={(event) =>
                handleUpdateInput({
                  required: event.target.checked,
                })
              }
            />
          }
          label={texts.inputLabelRequired}
        />

        <Divider />
        {settings}

        <Divider />
        <DependsOn {...{ idx, handleUpdateElement: handleUpdateInput }} />
      </Stack>
    </AccordionDetails>
  )
}

export default InputElementSettings
