// hooks
import { createContext } from 'react'
import { useAppSelector } from 'src/redux/hooks'

// types
import { ReactNode, FC } from 'react'
import { Language } from '../../hooks/languages'

// assets
import { dictionaryList, languages } from 'src/languages'

// create the language context with default selected language
export const LanguageContext = createContext({
  language: 'en' as Language,
  dictionary: dictionaryList.en,
})

interface CustomizedSnackbarProviderProps {
  children: ReactNode
}

// it provides the language context to app
const LanguageProvider: FC<CustomizedSnackbarProviderProps> = ({
  children,
}) => {

  const language = useAppSelector((state) => {
    const lan = state.appearance.locale.slice(0, 2)
    if (languages.map((l) => l as string).indexOf(lan) === -1) {
      return 'en' as Language
    }
    return lan as Language
  })
  console.log("language", language);

  const provider = {
    language,
    dictionary: dictionaryList[language],
    // userLanguageChange: (selected: Language) => {
    //   const defaultLanguage = window.localStorage.getItem('rcml-lang')
    //   const [userLanguage, setUserLanguage] = useState(defaultLanguage || 'en')
    //   setUserLanguage(selected)
    //   window.localStorage.setItem('rcml-lang', selected)
    // },
  }

  return (
    <LanguageContext.Provider value={provider}>
      {children}
    </LanguageContext.Provider>
  )
}

export default LanguageProvider
