// react
import ReactDOM from 'react-dom'

// components
import App from './App'
import SnackbarProvider from './components/providers/SnackbarProvider'
import { Provider } from 'react-redux'
import LanguageProvider from './components/providers/LanguageProvider'

// css
import './index.css'

// redux store
import { store } from './redux/store'

ReactDOM.render(
  <Provider store={store}>
    <LanguageProvider>
      <SnackbarProvider>
        <App />
      </SnackbarProvider>
    </LanguageProvider>
  </Provider>,
  document.getElementById('root')
)
