// redux-toolkit
import { createSlice } from '@reduxjs/toolkit'

// redux-thunks
import { getCommentData, getSiblings, saveComment } from './commentThunks'
import { saveConfig } from './configThunks'
import { checkDatabase, checkTableau, checkUsername } from './tableauThunks'

// types
import { PayloadAction } from '@reduxjs/toolkit'
import { BufferType, Buffer } from 'src/types/redux/buffer'

export const initialBufferState: Buffer = {
  databaseCheck: false,
  usernameCheck: false,
  tableauCheck: false,
  savingConfig: false,
  savingComment: false,
  fetchingTableauData: false,
  fetchingSavedFields: false,
  fetchingSiblingsData: false,
}

const bufferSlice = createSlice({
  name: 'buffer',
  initialState: initialBufferState,
  reducers: {
    activateBuffer: (state, action: PayloadAction<BufferType>) => {
      return { ...state, [action.payload]: true }
    },
    deactivateBuffer: (state, action: PayloadAction<BufferType>) => {
      return { ...state, [action.payload]: false }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveConfig.pending, (state) => {
      return { ...state, savingConfig: true }
    })
    builder.addCase(saveConfig.fulfilled, (state) => {
      return { ...state, savingConfig: false }
    })
    builder.addCase(checkDatabase.pending, (state) => {
      return { ...state, databaseCheck: true }
    })
    builder.addCase(checkDatabase.fulfilled, (state) => {
      return { ...state, databaseCheck: false }
    })
    builder.addCase(checkUsername.pending, (state) => {
      return { ...state, usernameCheck: true }
    })
    builder.addCase(checkUsername.fulfilled, (state) => {
      return { ...state, usernameCheck: false }
    })
    builder.addCase(checkTableau.pending, (state) => {
      return { ...state, tableauCheck: true }
    })
    builder.addCase(checkTableau.fulfilled, (state) => {
      return { ...state, tableauCheck: false }
    })
    builder.addCase(saveComment.pending, (state) => {
      return { ...state, savingComment: true }
    })
    builder.addCase(saveComment.fulfilled, (state) => {
      return { ...state, savingComment: false }
    })
    builder.addCase(getCommentData.pending, (state) => {
      return { ...state, fetchingSavedFields: true }
    })
    builder.addCase(getCommentData.fulfilled, (state) => {
      return { ...state, fetchingSavedFields: false }
    })
    builder.addCase(getSiblings.pending, (state) => {
      return { ...state, fetchingSiblingsData: true }
    })
    builder.addCase(getSiblings.fulfilled, (state) => {
      return { ...state, fetchingSiblingsData: false }
    })
  },
})

export const bufferActions = bufferSlice.actions

export default bufferSlice.reducer
