// hooks
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { useLanguage } from 'src/hooks/languages'

// mui
import { FormLabel } from '@mui/material'

// components
import CheckboxSwitch from '../../../form/CheckboxSwitch'
import Radiogroup from '../../../form/Radio'
import Datepicker from '../../../form/Datepicker'
import Dropdown from '../../../form/Dropdown'
import Rating from '../../../form/Rating'

// types
import { ChangeEvent, FC } from 'react'
import {
  CheckboxSwitchElement,
  DatepickerElement,
  DropdownElement,
  InputElement,
  RadiogroupElement,
  RatingElement,
  StyleElement,
  TextfieldElement,
  Value,
} from '../../../../types/redux/layout'
import Textfield from '../../../form/Textfield'

// actions
import { layoutActions } from '../../../../redux/layout'

interface EnablingValueProps {
  idx: number
}

const EnablingValue: FC<EnablingValueProps> = ({ idx }) => {
  const texts = useLanguage()

  const dispatch = useAppDispatch()

  const element = useAppSelector(
    (state) => state.layout[idx] as InputElement | StyleElement
  )
  const depEl = useAppSelector(
    (state) => state.layout[element.dependsOn] as InputElement | undefined
  )

  const updateEnablingValue = (enablingValue: Value) =>
    dispatch(
      layoutActions.updateEnablingValue({
        idx,
        enablingValue,
      })
    )

  const updateEnablingValueByEventTargetValue = (
    event: ChangeEvent<HTMLInputElement>
  ) => updateEnablingValue(event.target.value)

  const updateEnablingValueByEventTargetNumber = (
    event: ChangeEvent<HTMLInputElement>
  ) => updateEnablingValue(event.target.valueAsNumber)

  const updateEnablingValueByEventTargetChecked = (
    event: ChangeEvent<HTMLInputElement>
  ) => updateEnablingValue(event.target.checked)

  let specificInput = <></>

  if (depEl) {
    switch (depEl.type) {
      case 'Textfield':
        specificInput = (
          <Textfield
            {...{
              element: depEl as TextfieldElement,
              value: element.enablingValue,
              onChange: updateEnablingValueByEventTargetValue,
            }}
          />
        )
        break
      case 'Checkbox':
      case 'Switch':
        specificInput = (
          <CheckboxSwitch
            {...{
              element: depEl as CheckboxSwitchElement,
              value: Boolean(element.enablingValue),
              onChange: updateEnablingValueByEventTargetChecked,
            }}
          />
        )
        break
      case 'Radiogroup':
        specificInput = (
          <Radiogroup
            {...{
              element: depEl as RadiogroupElement,
              value: element.enablingValue,
              onChange: updateEnablingValueByEventTargetValue,
            }}
          />
        )
        break
      case 'Datepicker':
        specificInput = (
          <Datepicker
            {...{
              element: depEl as DatepickerElement,
              value: element.enablingValue,
              onChange: (newDate: Date | null) =>
                updateEnablingValue(newDate?.toDateString() || null),
            }}
          />
        )
        break
      case 'Dropdown':
        specificInput = (
          <Dropdown
            {...{
              element: depEl as DropdownElement,
              value: element.enablingValue,
              onChange: updateEnablingValueByEventTargetValue,
            }}
          />
        )
        break
      case 'Rating':
        specificInput = (
          <Rating
            {...{
              element: depEl as RatingElement,
              value: element.enablingValue,
              onChange: updateEnablingValueByEventTargetNumber,
            }}
          />
        )
        break
    }
  }

  return (
    <>
      {depEl && (
        <>
          <FormLabel component='legend'>
            {texts.inputLabelElementDependencyRevealingValue}
          </FormLabel>
          {specificInput}
        </>
      )}
    </>
  )
}

export default EnablingValue
