// hooks
import { useLanguage } from 'src/hooks/languages'

// mui
import { Alert, AlertTitle } from '@mui/material'

// types
import { MarkSelection } from '../../types/marks'
import { FC } from 'react'

interface MarkSelectionAlertProps {
  siblingsUids: number[]
  markSelection: MarkSelection
}

const MarkSelectionAlert: FC<MarkSelectionAlertProps> = ({
  siblingsUids,
  markSelection,
}) => {
  const texts = useLanguage()

  return (
    <Alert severity='info'>
      <AlertTitle>{texts.alertTitleFaultyMarkSelection}</AlertTitle>
      {siblingsUids.length > 1 && texts.alertTextMultipleSiblingsUids}
      {markSelection.commentUids.length > 0 &&
        markSelection.marks.length > markSelection.commentUids.length &&
        texts.alertTextMoreMarksThanComments}
      {markSelection.commentUids.length > 1 &&
        siblingsUids.length !== 1 &&
        texts.alertTextNonRelatedComments}
    </Alert>
  )
}

export default MarkSelectionAlert
