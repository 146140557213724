// hooks
import { useAppSelector } from '../../../../redux/hooks'
import useWheelHack from '../../../../hooks/numberInput'
import { useLanguage } from 'src/hooks/languages'

// mui
import {
  TextField,
  Stack,
  FormControlLabel,
  Switch,
  Chip,
  Divider,
} from '@mui/material'

// types
import { KeyboardEvent, ChangeEvent, WheelEvent, FC } from 'react'
import { InputElement, TextfieldElement } from '../../../../types/redux/layout'

// assets
import { textFieldStyleProps } from '../../../../assets/helpers/mui'
import TextfieldInputElement from 'src/components/form/Textfield'

interface TextfieldElementSettingsProps {
  idx: number
  handleUpdateInput: (options: Partial<InputElement>) => {
    payload: {
      idx: number
      options: Partial<InputElement>
    }
    type: string
  }
}

const TextfieldElementSettings: FC<TextfieldElementSettingsProps> = ({
  idx,
  handleUpdateInput,
}) => {
  const texts = useLanguage()

  const preventWheelDefault = useWheelHack()

  const element = useAppSelector(
    (state) => state.layout[idx] as TextfieldElement
  )

  return (
    <>
      {/* <TextField
        {...textFieldStyleProps('Default Value')}
        value={element.default || ''}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          handleUpdateInput({ default: event.target.value })
        }
        multiline={element.multirow}
        maxRows={
          element.multirow && !element.fixedRows && element.rows
            ? Number(element.rows)
            : undefined
        }
        rows={
          element.multirow && element.fixedRows && element.rows
            ? Number(element.rows)
            : undefined
        }
      /> */}
      <Stack direction='row' spacing={2}>
        <FormControlLabel
          control={
            <Switch
              checked={Boolean(element.multirow)}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                handleUpdateInput({
                  multirow: event.target.checked,
                  fixedRows: false,
                  rows: undefined,
                })
              }}
            />
          }
          label={texts.inputLabelTextfieldMultirow}
        />
        {element.multirow && (
          <FormControlLabel
            disabled={!element.multirow}
            control={
              <Switch
                checked={Boolean(element.fixedRows)}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleUpdateInput({
                    fixedRows: event.target.checked,
                  })
                }
              />
            }
            label={texts.inputLabelTextfieldFixedNumberOfRows}
          />
        )}
      </Stack>
      {element.multirow && (
        <TextField
          {...textFieldStyleProps(
            element.fixedRows
              ? texts.inputLabelTextfieldNumberOfRows
              : texts.inputLabelTextfieldMaximumNumberOfRows
          )}
          disabled={!element.multirow}
          inputProps={{
            type: 'number',
            min: 0,
            onKeyDown: (event: KeyboardEvent) =>
              ['e', 'E', '+'].includes(event.key) && event.preventDefault(),
            onWheel: (_: WheelEvent) => preventWheelDefault(),
            inputMode: 'numeric',
            pattern: '[0-9]*',
          }}
          value={(element.multirow && element.rows && element.rows) || ''}
          onChange={(event) => {
            let rows: number = Number(event.target.value)
            if (rows < 0) rows = 0
            handleUpdateInput({ rows })
          }}
        />
      )}
      <Divider textAlign='left'>
        <Chip
          label={texts.sectionHeaderDefaultValue}
          color='default'
          sx={{
            alignSelf: 'center',
            justifySelf: 'center',
            borderRadius: 1,
          }}
        />
      </Divider>
      <TextfieldInputElement
        {...{
          element,
          value: element.default,
          onChange: (event: ChangeEvent<HTMLInputElement>) =>
            handleUpdateInput({ default: event.target.value }),
        }}
      />
    </>
  )
}

export default TextfieldElementSettings
