// hooks
import { useLanguage } from 'src/hooks/languages'

// mui
import { MenuItem, TextField } from '@mui/material'

// types
import { DropdownElement, Value } from '../../types/redux/layout'
import { FC } from 'react'

// assets
import { textFieldStyleProps } from '../../assets/helpers/mui'

interface DropdownInputElementProps {
  autoFocus?: boolean
  element: DropdownElement
  value: Value
  onChange: any
}

const DropdownInputElement: FC<DropdownInputElementProps> = ({
  autoFocus,
  element,
  value,
  onChange,
}) => {
  const texts = useLanguage()
  return (
    <TextField
      autoFocus={!!autoFocus}
      {...{ ...textFieldStyleProps(element.label), onChange }}
      // focused={expanded === idx}
      error={element.inputErrors.required}
      helperText={
        element.inputErrors.required ? texts.formHelperFieldRequired : ''
      }
      select
      value={value || ''}
    >
      {element.options.map((option, i) => (
        <MenuItem key={i} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default DropdownInputElement
