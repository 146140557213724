import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../api'

// types
import {
  WorksheetInitializationInfo,
  TableauWorksheets,
  UsernameSheet,
} from 'src/types/redux/tableau'
import { ConfigState } from 'src/types/redux/config'

export const checkUsername = createAsyncThunk<UsernameSheet, void, {}>(
  'tableau/username',
  async () => {
    const usernameSheet: UsernameSheet = { sheetExists: false }
    const sheet =
      tableau.extensions.dashboardContent?.dashboard.worksheets.find(
        (worksheet) => worksheet.name === 'Username'
      )
    if (sheet) {
      usernameSheet.sheetExists = true
      usernameSheet.name = await sheet.getSummaryDataAsync().then((sd) => {
        if (sd.totalRowCount > 0) return sd.data[0][0].nativeValue as string
        return undefined
      })
    }
    return usernameSheet
  }
)

export const checkDatabase = createAsyncThunk<{
  success: boolean
  cbOutdated: boolean
}>(
  'initialize',
  async () =>
    await api
      .get('initialize')
      .then(async (response) => {
        const res = {
          success: !!response.data.existance,
          cbOutdated: response.data.cbOutdated,
        }
        return res
        // need to check the version of initialized configs by using a database call! if not equal backend version -> migrate -> return changes to frontend. Tableau config not applicable to version -> fetch version from database!
      })
      .catch((err) => {
        console.log(err)
        return { success: false, cbOutdated: false }
      })
)

export const checkTableau = createAsyncThunk<
  TableauWorksheets,
  void,
  { state: { config: ConfigState } }
>('tableau/check-dashboard-initialization', async (_, { getState }) => {
  const { config } = getState()

  console.log("checkTableau config:", config);

  const commentableWorksheets =
    config.dimensions?.map((wsDim) => wsDim.worksheet) || []

  let worksheetsInfo: { [key: string]: WorksheetInitializationInfo } = {}

  const worksheets =
    tableau.extensions.dashboardContent?.dashboard.worksheets.filter(
      (dashboardWorksheet) =>
        commentableWorksheets.indexOf(dashboardWorksheet.name) > -1
    )

  if (worksheets) {
    for (const worksheet of worksheets) {
      let worksheetInfo: WorksheetInitializationInfo = {
        commentingDataSourceExists: false,
        dataSourceIsLive: false,
        commentUidExists: false,
        siblingsUidExists: false,
      }

      await worksheet
        .getDataSourcesAsync()
        .then(async (dataSources) => {
          for (const dataSource of dataSources.flat()) {
            await dataSource
              .getLogicalTablesAsync()
              .then(async (logicalTables) => {
                console.log(logicalTables);
                const tableName = `cb_comments_${config.name}`

                if(dataSource.fields.find(
                  (field) => field.id.startsWith('Comment Uid')
                )) {
                  console.log("found comment id");
                  worksheetInfo.commentingDataSourceExists = true;
                }

                if (
                  logicalTables[0].id.slice(0, tableName.length) === tableName
                )
                  worksheetInfo.commentingDataSourceExists = true
                if (worksheetInfo.commentingDataSourceExists) {
                  if (!dataSource.isExtract)
                    worksheetInfo.dataSourceIsLive = true
                  if (
                    dataSource.fields.find(
                      (field) => field.id.startsWith('Siblings Uid')
                    )?.role !== 'dimension'
                  )
                    console.log(
                      'make sure Siblings Uid is a Dimension not a Measure'
                    )
                  if (
                    dataSource.fields.find(
                      (field) => field.id.startsWith('Comment Uid')
                    )?.role !== 'dimension'
                  )
                    console.log(
                      'make sure Comment Uid is a Dimension not a Measure'
                    )
                  await worksheet
                    .getSummaryColumnsInfoAsync()
                    .then((colInfo) => {
                      worksheetInfo.commentUidExists =
                        colInfo.findIndex(
                          (col) => col.fieldName.startsWith('ATTR(Comment Uid')
                        ) > -1
                      worksheetInfo.siblingsUidExists =
                        colInfo.findIndex(
                          (col) => col.fieldName.startsWith('ATTR(Siblings Uid')
                        ) > -1
                    })
                }
              }, (error) => {
                // The user has no permissions to access the underlying data.
                // E.g. if a viewer license is used instead of creators.
                // Viewers are not concerned with the configuration, so just accept.
                worksheetInfo.commentingDataSourceExists = true;
                if (!dataSource.isExtract)
                    worksheetInfo.dataSourceIsLive = true
                worksheetInfo.commentUidExists = true;
                worksheetInfo.siblingsUidExists = true;
                //console.log("Error:", error)
              })
          }
        })
        .catch((e) => console.log(e))
        .finally(() => (worksheetsInfo[worksheet.name] = worksheetInfo))
    }
  }

  return worksheetsInfo || {}
})
