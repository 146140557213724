// hooks
import { useState, useEffect, FC } from 'react'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { useLanguage } from 'src/hooks/languages'

// mui
import { alpha } from '@mui/material/styles'
import { visuallyHidden } from '@mui/utils'
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  TablePagination,
  Checkbox,
  IconButton,
  Toolbar,
  Tooltip,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

// redux-thunks
import { deleteComments, getSiblings } from '../../redux/commentThunks'

// types
import PropTypes from 'prop-types'
import { MarkSelection } from '../../types/marks'
import { Value } from '../../types/redux/layout'
import { Dispatch, MouseEvent, ChangeEvent, Fragment } from 'react'

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

type Order = 'asc' | 'desc'
function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: Value }, b: { [key in Key]: Value }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

interface EnhancedTableProps {
  numSelected: number
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  order: Order
  orderBy: string
  rowCount: number
}

interface HeadCell {
  label: string
  numeric: boolean
  disablePadding: boolean
}

interface EnhancedTableToolbarProps {
  numSelected: number
}

interface DimensionsTableProps {
  siblingsUid: number | undefined
  siblings: { [key: string]: Value; commentUid: number }[]
  markSelection: MarkSelection
  selectedCommentUids: number[]
  setSelectedCommentUids: Dispatch<number[]>
}

const DimensionsTable: FC<DimensionsTableProps> = ({
  siblingsUid,
  siblings,
  markSelection,
  selectedCommentUids,
  setSelectedCommentUids,
}) => {
  const texts = useLanguage()

  const dispatch = useAppDispatch()

  const dimensions = useAppSelector(
    (state) =>
      state.dimensions?.find(
        (wsDim) => wsDim.worksheet === markSelection.worksheet
      )?.dimensions
  )

  const [headCells, setHeadCells] = useState<HeadCell[]>([])
  const [rows, setRows] = useState<
    { [key: string]: Value; commentUid: number }[]
  >([])
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<string>(
    (dimensions && dimensions[0]) || ''
  )
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(5)

  const isSelected = (dimUID: number) =>
    selectedCommentUids.indexOf(dimUID) !== -1

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  useEffect(() => {
    if (markSelection.dimensions.length !== 0) {
      if (siblingsUid)
      {
        let comments = siblings;
        setRows(comments);
        setSelectedCommentUids(
          comments
            .map((comment) =>
              markSelection.commentUids.indexOf(
                (comment.commentUid as number | undefined) || -1
              ) > -1
                ? (comment.commentUid as number)
                : -1
            )
            .filter((uid) => uid > -1)
        )
      }
        /*
        dispatch(
          getSiblings({ siblingsUid, worksheet: markSelection.worksheet })
        )
          .unwrap()
          .then((comments) => {
            setRows(comments)
            setSelectedCommentUids(
              comments
                .map((comment) =>
                  markSelection.commentUids.indexOf(
                    (comment.commentUid as number | undefined) || -1
                  ) > -1
                    ? (comment.commentUid as number)
                    : -1
                )
                .filter((uid) => uid > -1)
            )
          })
          */
      else {
        setSelectedCommentUids(markSelection.commentUids)
        let row = { commentUid: markSelection.commentUids[0] } as {
          [key: string]: Value
          commentUid: number
        }
        dimensions?.forEach(
          (dimension, idx) => (row[dimension] = markSelection.marks[0][idx])
        )
        setRows([row])
      }

      setHeadCells(
        dimensions?.map((dimension) => {
          return {
            numeric: true,
            disablePadding: true,
            label: dimension,
          } as HeadCell
        }) || ([] as HeadCell[])
      )
    }
  }, [
    dispatch,
    dimensions,
    markSelection,
    siblingsUid,
    siblings,
    setSelectedCommentUids,
    setHeadCells,
  ])

  const handleDeleteMarksFromCom = () =>
    dispatch(deleteComments(selectedCommentUids))
      .unwrap()
      .then((success) => {
        if (success) {
          selectedCommentUids.length === rows.length &&
            tableau.extensions.ui.closeDialog('refresh')
          setRows(
            rows.filter(
              (row) => selectedCommentUids.indexOf(row.commentUid) === -1
            )
          )
          setSelectedCommentUids([])
        }
      })

  const EnhancedTableHead = (props: EnhancedTableProps) => {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort,
    } = props
    const createSortHandler =
      (property: string) => (event: MouseEvent<unknown>) =>
        onRequestSort(event, property)

    return (
      <TableHead>
        <TableRow>
          <TableCell key='checkbox' padding='checkbox'>
            <Checkbox
              color='primary'
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.label}
              align='left'
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.label && order}
            >
              <TableSortLabel
                active={orderBy === headCell.label}
                direction={orderBy === headCell.label ? order : 'asc'}
                onClick={createSortHandler(headCell.label)}
              >
                {headCell.label}
                {orderBy === headCell.label ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    )
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  }

  const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const { numSelected } = props

    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color='inherit'
            variant='subtitle1'
            component='div'
          >
            {numSelected} {texts.selected}
          </Typography>
        ) : (
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant='subtitle2'
            id='tableTitle'
            component='div'
          >
            {texts.paragraphEditCommentCheckMarksToEdit}
          </Typography>
        )}
        <Tooltip title={texts.tooltipDeleteCommentForAllDimensions}>
          <span>
            <IconButton
              disabled={numSelected === 0}
              onClick={handleDeleteMarksFromCom}
            >
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Toolbar>
    )
  }

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  }

  const handleRequestSort = (event: MouseEvent<unknown>, property: string) => {
    event.preventDefault()
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSels: number[] = rows.map((row) => row.commentUid as number)
      setSelectedCommentUids(newSels)
      return
    }
    setSelectedCommentUids([])
  }

  const handleClick = (event: MouseEvent<unknown>, uid: number) => {
    event.preventDefault()
    const selIndex = selectedCommentUids.indexOf(uid)
    let newSels: number[] = []

    if (selIndex === -1) {
      newSels = newSels.concat(selectedCommentUids, uid)
    } else if (selIndex === 0) {
      newSels = newSels.concat(selectedCommentUids.slice(1))
    } else if (selIndex === selectedCommentUids.length - 1) {
      newSels = newSels.concat(selectedCommentUids.slice(0, -1))
    } else if (selIndex > 0) {
      newSels = newSels.concat(
        selectedCommentUids.slice(0, selIndex),
        selectedCommentUids.slice(selIndex + 1)
      )
    }

    setSelectedCommentUids(newSels)
  }

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <Fragment>
      <EnhancedTableToolbar numSelected={selectedCommentUids.length} />
      <TableContainer>
        <Table aria-labelledby='dimensions-table-title' size='small'>
          <EnhancedTableHead
            numSelected={selectedCommentUids.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {rows
              .slice()
              .sort(getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                const isItemSelected = isSelected(row.commentUid as number)
                const labelId = `enhanced-table-checkbox-${
                  row.commentUid as number
                }`

                return (
                  <TableRow
                    key={row.commentUid as number}
                    hover
                    onClick={(event) =>
                      handleClick(event, row.commentUid as number)
                    }
                    role='checkbox'
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    selected={isItemSelected}
                  >
                    <TableCell padding='checkbox'>
                      <Checkbox
                        color='primary'
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </TableCell>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={`${row.commentUid}-${headCell.label}`}
                        align='left'
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                      >
                        {row[headCell.label]}
                      </TableCell>
                    ))}
                  </TableRow>
                )
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 33 * emptyRows }}>
                {' '}
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component='div'
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Fragment>
  )
}

export default DimensionsTable
