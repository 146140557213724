import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../api'

// assets
import { isNull } from 'lodash'
import {
  findColIdx,
  findRow,
  findRows,
  getCbDataTable,
  getValFromRow,
  findColumnIndex,
  getCbSummaryDataTable,
  getCbDataTableReader
} from '../assets/helpers/tableau'

// types
import { MarkSelection } from '../types/marks'
import { InputElement, LayoutState, Value } from './../types/redux/layout'
import { ConfigState } from '../types/redux/config'
import { DimensionsState } from './../types/redux/dimensions'

export const saveComment = createAsyncThunk<
  { success: boolean; duplicateComment: boolean },
  { username: string; markSelection: MarkSelection; commentUids?: number[] },
  {
    state: {
      config: ConfigState
      layout: LayoutState
      dimensions: DimensionsState
    }
  }
>(
  'comment/add',
  async ({ username, markSelection, commentUids }, { getState }) => {
    const { layout, config, dimensions } = getState()

    const comment = {
      username,
      configName: config.name,
      fields: layout
        .filter((element): element is InputElement => 'dbCol' in element)
        .map((element) => {
          return { dbCol: element.dbCol, value: element.value }
        }),
      selReq: !isNull(dimensions),
      markSelection,
      commentUids,
    }
    console.log("sent commnet: ", comment)

    return await api
      .put(
        `comment/${commentUids && commentUids.length > 0 ? 'edit' : 'add'}`,
        {
          username,
          configName: config.name,
          fields: layout
            .filter((element): element is InputElement => 'dbCol' in element)
            .map((element) => {
              return { dbCol: element.dbCol, value: element.value }
            }),
          selReq: !isNull(dimensions),
          markSelection,
          commentUids,
        }
      )
      .then((response) => response.data)
  }
)

export const getSavedFields = createAsyncThunk<
  { layout: LayoutState; siblingsUid?: number },
  { commentUid: number; worksheet: string },
  { state: { config: ConfigState; layout: LayoutState } }
>('comment/get-fields', async ({ commentUid, worksheet }, { getState }) => {
  let { config, layout } = getState()

  const commentWorksheet =
    tableau.extensions.dashboardContent?.dashboard.worksheets.find(
      (ws) => ws.name === worksheet
    )

  const column_names = layout.filter((element): element is InputElement => 'dbCol' in element)?.map((element) => {
      return element.dbCol;
    });

  const tableName = `cb_comments_${config.name}`
  const commentActionWorksheet =
    tableau.extensions.dashboardContent?.dashboard.worksheets.find(
      (ws) => ws.name === tableName
    )

  if(commentActionWorksheet)
  {
    console.log("action worksheet found");
    const dataTableReader = await commentActionWorksheet.getSummaryDataReaderAsync(undefined, {ignoreSelection: true});
    const dataTable = await dataTableReader.getAllPagesAsync();
    await dataTableReader.releaseAsync();

    console.log("action data table:", dataTable);

    const all_columns_present = column_names.every((column) => {
      const dbColIdx = findColIdx({ dataTable, key: column })
      return dbColIdx >= 0;
    });
    console.log("All sheet columns present:", all_columns_present);

    const siblingsUidColIdx = findColumnIndex({ dataTable, key: 'Siblings Uid' })
    console.log("siblingsUidColIdx", siblingsUidColIdx);

    if(all_columns_present)
      {
        const commentRow = dataTable.data[0];

        return {
          layout: layout.map((element) => {
            if ('dbCol' in element) {
              const dbColIdx = findColIdx({ dataTable, key: element.dbCol })
              let required_error = element.inputErrors.required
              if (element.required)
                required_error =
                element.type === 'Checkbox' ||
                element.type === 'Switch'
                    ? !element.value
                    : !!element.value
              return {
                ...element,
                value: (dbColIdx > -1 && commentRow) ? commentRow[dbColIdx].nativeValue : element.value,
                inputErrors:
                  {
                    ...element.inputErrors,
                    required: required_error
                  }
              }
            }
            return element
            }),
          siblingsUid: siblingsUidColIdx
            ? getValFromRow({
                row: commentRow,
                colIdx: siblingsUidColIdx,
              })
            : undefined,
        }

        //
        // Not all fields are present. Try to find the action sheet
        //
      }
  }

  if(commentWorksheet)
  {
    //
    // Try to retrieve the comment data form the selected Mark
    //

    const ans = await commentWorksheet.getSelectedMarksAsync().then(async function (marks) {
      // Get the first DataTable for our selected marks (usually there is just one)
      const worksheetData = marks.data[0];
      console.log("Mark data:", worksheetData);
      // Map the data into a format for display, etc.
      
      const all_columns_present = column_names.every((column) => {
        const dbColIdx = findColIdx({ dataTable: worksheetData, key: column })
        return dbColIdx >= 0;
      });
      console.log("All columns present:", all_columns_present);

      //const colIdx = findColumnIndex({ dataTable: worksheetData, key: 'Comment Uid' })
      const siblingsUidColIdx = findColumnIndex({ dataTable: worksheetData, key: 'ATTR(Siblings Uid' })
      const siblingsUid = getValFromRow({ row: worksheetData.data[0], colIdx: siblingsUidColIdx })
      console.log("siblingsUid", siblingsUid);

      if(all_columns_present)
      {
        const commentRow = worksheetData.data[0];

        return {
          layout: layout.map((element) => {
            if ('dbCol' in element) {
              const dbColIdx = findColIdx({ dataTable: worksheetData, key: element.dbCol })
              let required_error = element.inputErrors.required
              if (element.required)
                required_error =
                element.type === 'Checkbox' ||
                element.type === 'Switch'
                    ? !element.value
                    : !!element.value
              return {
                ...element,
                value: (dbColIdx > -1 && commentRow) ? commentRow[dbColIdx].nativeValue : element.value,
                inputErrors:
                  {
                    ...element.inputErrors,
                    required: required_error
                  }
              }
            }
            return element
            }),
          siblingsUid: siblingsUid
        }
      }

      return await getCbDataTableReader({ configName: config.name, commentWorksheet, columnsToInclude: column_names }).then(
        async (dataTableReader) => {

          if(dataTableReader)
          {
            for (let currentPage = 0; currentPage < dataTableReader.pageCount; currentPage++) {
              const currentPageDataTable = await dataTableReader.getPageAsync(currentPage);

              console.log("currentPageDataTable:", currentPage, ":", currentPageDataTable);
              if (currentPageDataTable) {
                const commentRow = findRow({
                  dataTable: currentPageDataTable,
                  key: 'Comment Uid',
                  val: commentUid,
                })
                console.log("comment row:", commentRow);
        
                if (commentRow)
                {
                  const result = {
                    layout: layout.map((element) => {
                      if ('dbCol' in element) {
                        const dbColIdx = findColIdx({ dataTable: currentPageDataTable, key: element.dbCol })
                        let required_error = element.inputErrors.required
                        if (element.required)
                          required_error =
                          element.type === 'Checkbox' ||
                          element.type === 'Switch'
                              ? !element.value
                              : !!element.value
                        return {
                          ...element,
                          value: (dbColIdx > -1 && commentRow) ? commentRow[dbColIdx].nativeValue : element.value,
                          inputErrors:
                            {
                              ...element.inputErrors,
                              required: required_error
                            }
                        }
                      }
                      return element
                      }),
                    siblingsUid: siblingsUid
                  }
                  await dataTableReader.releaseAsync();
                  return result;
                }
              }
            }
            await dataTableReader.releaseAsync();
            return { layout }
          }
          return { layout }
      });
    });
    if (ans) return ans;
  }
  return { layout }
})

// TODO: @Perf Merge with get saved fields
export const getSiblings = createAsyncThunk<
  { [key: string]: Value; commentUid: number }[],
  { siblingsUid: number; worksheet: string },
  { state: { config: ConfigState } }
>('comment/get-siblings', async ({ siblingsUid, worksheet }, { getState }) => {
  const { config } = getState()
  const dimensions =
    config.dimensions?.find((wsDims) => wsDims.worksheet === worksheet)
      ?.dimensions || ([] as string[])

  const commentWorksheet =
    tableau.extensions.dashboardContent?.dashboard.worksheets.find(
      (ws) => ws.name === worksheet
    )

  if(!commentWorksheet) {
    return [] as { [key: string]: Value; commentUid: number }[]
  }

  return getCbSummaryDataTable({ configName: config.name, commentWorksheet }).then(
    (dataTable) => {
      console.log("getCbSummaryDataTable:", dataTable);
      if (dataTable) {
        const commentRows = findRows({
          dataTable,
          key: 'ATTR(Siblings Uid',
          val: siblingsUid,
        })
        if (commentRows) {
          const commentUidColIdx = findColIdx({ dataTable, key: 'ATTR(Comment Uid' })
          const dimensionsColIdc = dimensions.map((dimension) =>
            findColIdx({ dataTable, key: dimension })
          )
          if (commentUidColIdx > -1 && dimensionsColIdc) {
            return commentRows.map((commentRow) => {
              let comment = {
                commentUid: commentRow[commentUidColIdx].nativeValue as number,
              } as {
                [key: string]: Value
                commentUid: number
              }
              dimensions.forEach(
                (key, idx) =>
                  (comment[key] =
                    commentRow[dimensionsColIdc[idx] as number]?.nativeValue ||
                    null)
              )
              return comment
            })
          }
        }
      }
      return [] as { [key: string]: Value; commentUid: number }[]
    }
  )
})

export const getConfig = createAsyncThunk<
  ConfigState,
  boolean,
  { state: { config: ConfigState } }
>('comment/get-config', async (_, { getState }) => {
  const { config } = getState()
  return config;
})

export const getCommentData = createAsyncThunk<
  { layout: LayoutState; siblingsUid?: number, siblings: { [key: string]: Value; commentUid: number }[] },
  { commentUid: number; worksheet: string },
  { state: { config: ConfigState; layout: LayoutState } }
>('comment/get-comment-data', async ({ commentUid, worksheet }, { getState }) => {
  let { config, layout } = getState()

  const dimensions =
    config.dimensions?.find((wsDims) => wsDims.worksheet === worksheet)
      ?.dimensions || ([] as string[])

  const commentWorksheet =
    tableau.extensions.dashboardContent?.dashboard.worksheets.find(
      (ws) => ws.name === worksheet
    )

  let siblings = [] as { [key: string]: Value; commentUid: number }[]

  if(!commentWorksheet) {
    return { layout, siblings, siblingsUid: undefined }
  }

  const column_names = layout.filter((element): element is InputElement => 'dbCol' in element)?.map((element) => {
    return element.dbCol;
  });

  const marksData = await commentWorksheet.getSelectedMarksAsync().then((marks) => {
    return marks.data[0];
  });
  
  console.log("Mark data:", marksData);

  

  //
  // Get siblings data if it has siblings (siblingsUid not Null)
  //

  const siblingsUidColIdx = findColumnIndex({ dataTable: marksData, key: 'ATTR(Siblings Uid' })
  const siblingsUid = getValFromRow({ row: marksData.data[0], colIdx: siblingsUidColIdx })
  console.log("siblingsUid", siblingsUid);

  if(siblingsUid)
  {
    // TODO: Switch to data table reader
    siblings = await getCbSummaryDataTable({ configName: config.name, commentWorksheet }).then(
      (dataTable) => {
        console.log("getCbSummaryDataTable:", dataTable);
        if (dataTable) {
          // TODO: @Perf: Are these the same indexes as for the selected mark?
          const commentRows = findRows({
            dataTable,
            key: 'ATTR(Siblings Uid',
            val: siblingsUid,
          })
          if (commentRows) {
            const commentUidColIdx = findColIdx({ dataTable, key: 'ATTR(Comment Uid' })
            const dimensionsColIdc = dimensions.map((dimension) =>
              findColIdx({ dataTable, key: dimension })
            );
            if (commentUidColIdx > -1 && dimensionsColIdc) {
              return commentRows.map((commentRow) => {
                let comment = {
                  commentUid: commentRow[commentUidColIdx].nativeValue as number,
                } as {
                  [key: string]: Value
                  commentUid: number
                }
                dimensions.forEach(
                  (key, idx) =>
                    (comment[key] =
                      commentRow[dimensionsColIdc[idx] as number]?.nativeValue ||
                      null)
                )
                return comment
              })
            }
          }
        }
        return [] as { [key: string]: Value; commentUid: number }[]
      }
    )
  }

  //
  // Try to retrieve the comment data from the selected Mark
  //

  const all_columns_present = column_names.every((column) => {
    const dbColIdx = findColIdx({ dataTable: marksData, key: column })
    return dbColIdx >= 0;
  });
  console.log("All columns present in mark:", all_columns_present);

  if(all_columns_present)
  {
    const commentRow = marksData.data[0];

    return {
      layout: layout.map((element) => {
        if ('dbCol' in element) {
          const dbColIdx = findColIdx({ dataTable: marksData, key: element.dbCol })
          let required_error = element.inputErrors.required
          if (element.required)
            required_error =
            element.type === 'Checkbox' ||
            element.type === 'Switch'
                ? !element.value
                : !!element.value
          return {
            ...element,
            value: (dbColIdx > -1 && commentRow) ? commentRow[dbColIdx].nativeValue : element.value,
            inputErrors:
              {
                ...element.inputErrors,
                required: required_error
              }
          }
        }
        return element
        }),
      siblingsUid: siblingsUid,
      siblings
    }
  }

  //
  // Not all fields are present. Try to find the action sheet
  //

  const tableName = `cb_comments_${config.name}`
  const commentActionWorksheet =
  tableau.extensions.dashboardContent?.dashboard.worksheets.find(
    (ws) => ws.name === tableName
  )

  if(commentActionWorksheet)
  {
    console.log("action worksheet found");
    const dataTableReader = await commentActionWorksheet.getSummaryDataReaderAsync(undefined, {ignoreSelection: true});
    const dataTable = await dataTableReader.getAllPagesAsync();
    await dataTableReader.releaseAsync();

    console.log("action data table:", dataTable);

    const all_columns_present = column_names.every((column) => {
      const dbColIdx = findColIdx({ dataTable, key: column })
      return dbColIdx >= 0;
    });
    console.log("All sheet columns present:", all_columns_present);

    if(all_columns_present)
    {
      const commentRow = dataTable.data[0];

      return {
        layout: layout.map((element) => {
          if ('dbCol' in element) {
            const dbColIdx = findColIdx({ dataTable, key: element.dbCol })
            let required_error = element.inputErrors.required
            if (element.required)
              required_error =
              element.type === 'Checkbox' ||
              element.type === 'Switch'
                  ? !element.value
                  : !!element.value
            return {
              ...element,
              value: (dbColIdx > -1 && commentRow) ? commentRow[dbColIdx].nativeValue : element.value,
              inputErrors:
                {
                  ...element.inputErrors,
                  required: required_error
                }
            }
          }
          return element
          }),
        siblingsUid: siblingsUid,
        siblings
      }
    }
  }
  
  return await getCbDataTableReader({ configName: config.name, commentWorksheet, columnsToInclude: column_names }).then(
    async (dataTableReader) => {

      if(dataTableReader)
      {
        for (let currentPage = 0; currentPage < dataTableReader.pageCount; currentPage++) {
          const currentPageDataTable = await dataTableReader.getPageAsync(currentPage);

          console.log("currentPageDataTable:", currentPage, ":", currentPageDataTable);
          if (currentPageDataTable) {
            const commentRow = findRow({
              dataTable: currentPageDataTable,
              key: 'Comment Uid',
              val: commentUid,
            })
            console.log("comment row:", commentRow);
    
            if (commentRow)
            {
              const result = {
                layout: layout.map((element) => {
                  if ('dbCol' in element) {
                    const dbColIdx = findColIdx({ dataTable: currentPageDataTable, key: element.dbCol })
                    let required_error = element.inputErrors.required
                    if (element.required)
                      required_error =
                      element.type === 'Checkbox' ||
                      element.type === 'Switch'
                          ? !element.value
                          : !!element.value
                    return {
                      ...element,
                      value: (dbColIdx > -1 && commentRow) ? commentRow[dbColIdx].nativeValue : element.value,
                      inputErrors:
                        {
                          ...element.inputErrors,
                          required: required_error
                        }
                    }
                  }
                  return element
                  }),
                siblingsUid: siblingsUid,
                siblings
              }
              await dataTableReader.releaseAsync();
              return result;
            }
          }
        }
        await dataTableReader.releaseAsync();
        return { layout, siblings }
      }
      return { layout, siblings }
  });
})

export const deleteComments = createAsyncThunk<
  boolean,
  number[],
  { state: { config: ConfigState } }
>('comment/delete', async (commentUids, { getState }) => {
  const { config } = getState()
  return await api
    .post('comment/delete', { commentUids, configName: config.name })
    .then((response) => response.data.success)
})
