// mui
import { createTheme } from '@mui/material'

// types
import { PaletteOptions, TextFieldProps } from '@mui/material'
import { AppearanceState } from '../../types/redux/appearance'

// assets
import * as locales from '@mui/material/locale'
import {
  coorperateError,
  coorperatePrimary,
  coorperateSecondary,
  coorperateSuccess,
  coorperateWarning,
} from '../../redux/appearance'

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    cancel: Palette['primary']
    save: Palette['primary']
    refresh: Palette['primary']
    add: Palette['primary']
    edit: Palette['primary']
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    cancel?: PaletteOptions['primary']
    save?: PaletteOptions['primary']
    refresh?: PaletteOptions['primary']
    add?: PaletteOptions['primary']
    edit?: PaletteOptions['primary']
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    cancel: true
    save: true
    refresh: true
    add: true
    edit: true
  }
}

export const createThemeFromApperance = (
  appearance: AppearanceState | undefined
) => {
  const { palette } = createTheme()
  let pal: PaletteOptions = {
    primary: {
      main: coorperatePrimary,
    },
    secondary: {
      main: coorperateSecondary,
    },
    error: {
      main: coorperateError,
    },
    warning: {
      main: coorperateWarning,
    },
    success: {
      main: coorperateSuccess,
    },
  }

  if (appearance) {
    pal.add = palette.augmentColor({
      color: {
        main: appearance.addCommentColor,
      },
    })
    pal.edit = palette.augmentColor({
      color: {
        main: appearance.editCommentColor,
      },
    })
    pal.cancel = palette.augmentColor({
      color: {
        main: appearance.dialogCancelColor,
      },
    })
    pal.save = palette.augmentColor({
      color: {
        main: appearance.dialogSaveColor,
      },
    })
    pal.refresh = palette.augmentColor({
      color: {
        main: appearance.dialogRefreshColor,
      },
    })
  } else {
    pal.add = palette.augmentColor({
      color: {
        main: palette.primary.main,
      },
    })
    pal.edit = palette.augmentColor({
      color: {
        main: palette.secondary.main,
      },
    })
    pal.cancel = palette.augmentColor({
      color: {
        main: palette.warning.main,
      },
    })
    pal.save = palette.augmentColor({
      color: {
        main: palette.secondary.main,
      },
    })
    pal.refresh = palette.augmentColor({
      color: {
        main: palette.primary.main,
      },
    })
  }
  return createTheme(
    {
      palette: { ...pal, mode: appearance?.darkMode ? 'dark' : 'light' },
    },
    locales[appearance?.locale || 'enUS']
  )
}

export const textFieldStyleProps = (label: string): TextFieldProps => {
  return {
    variant: 'outlined',
    size: 'small',
    fullWidth: true,
    label,
    key: label,
  }
}
