// hooks
import { useLanguage } from 'src/hooks/languages'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { useMemo, useState } from 'react'

// mui
import { Stack, Button, Tooltip, Typography } from '@mui/material'

// components
import Thief from './dialogs/ThiefDialog'
import ThiefIcon from '../icons/Thief'

// types
import { FC } from 'react'
import { STabs } from '.'

// redux-actions
import { configurationActions } from '../../redux/config'

// assets
import { Undo } from '@mui/icons-material'

interface SetupAppBarProps {
  s: STabs
}

const SetupAppBar: FC<SetupAppBarProps> = ({ s }) => {
  const texts = useLanguage()

  const dispatch = useAppDispatch()

  const config = useAppSelector((state) => state.config)
  const alreadySaved = useAppSelector((state) => !!state.config.appearance)

  const thiefDisabled = useMemo(
    () => (alreadySaved && s === 'Layout') || s === 'Dimensions',
    [alreadySaved, s]
  )

  const [openThief, setOpenThief] = useState<boolean>(false)

  const getTabName = (tab: STabs) => {
    switch (tab) {
      case 'Appearance':
        return `${texts.tabLabelAppearance} `
      case 'Layout':
        return `${texts.tabLabelLayout} `
      case 'Dimensions':
        return `${texts.tabLabelDimensions} `
      default:
        return ''
    }
  }

  const ThiefButton = () => (
    <Button
      variant='contained'
      endIcon={<ThiefIcon />}
      disabled={thiefDisabled}
      onClick={() => setOpenThief(true)}
      sx={{ whiteSpace: 'nowrap' }}
    >
      {getTabName(s)}
      {texts.buttonTextThief}
    </Button>
  )

  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      spacing={2}
    >
      {thiefDisabled ? (
        <Tooltip
          title={
            <>
              {!!config.appearance && s === 'Layout' && (
                <Typography variant='body2'>
                  {texts.tooltipThiefCurrentLayoutAlreadySaved}
                </Typography>
              )}
              {!!config.dimensions && s === 'Dimensions' && (
                <Typography variant='body2'>
                  {texts.tooltipThiefCurrentDimensionsAlreadySaved}
                </Typography>
              )}
            </>
          }
        >
          <span>
            <ThiefButton />
          </span>
        </Tooltip>
      ) : (
        <ThiefButton />
      )}
      <Tooltip
        title={
          <>
            {config.changed && (
              <Typography variant='body2'>
                {texts.tooltipDiscardConfigChanges}
              </Typography>
            )}
            {!config.changed && (
              <Typography variant='body2'>
                {texts.tooltipNoChangesToDiscard}
              </Typography>
            )}
          </>
        }
      >
        <span>
          <Button
            variant='contained'
            color='secondary'
            endIcon={<Undo />}
            disabled={!config.changed}
            onClick={() =>
              dispatch(configurationActions.discardChanges(config))
            }
            sx={{ whiteSpace: 'nowrap' }}
          >
            {texts.buttonTextDiscard}
          </Button>
        </span>
      </Tooltip>
      <Thief
        {...{
          s,
          openThief,
          setOpenThief,
        }}
      />
    </Stack>
  )
}

export default SetupAppBar
