// hooks
import { useAppSelector } from 'src/redux/hooks'

// mui
import { AccordionSummary, Stack, Chip } from '@mui/material'

// types
import { FC } from 'react'

interface WorksheetAccordionSummaryProps {
  worksheet: string
}

const WorksheetAccordionSummary: FC<WorksheetAccordionSummaryProps> = ({
  worksheet,
}) => {
  const commentable = useAppSelector(
    (state) =>
      state.dimensions?.findIndex((ws) => ws.worksheet === worksheet) !== -1
  )

  return (
    <AccordionSummary
      sx={{ width: '100%' }}
      aria-controls='accordion-summary'
      id='accordion-summary'
    >
      <Stack
        direction='row'
        spacing={2}
        alignItems='center'
        justifyContent='space-between'
        width='stretch'
      >
        <Chip
          label={worksheet}
          color={commentable ? 'primary' : 'default'}
          sx={{ cursor: 'pointer' }}
        />
      </Stack>
    </AccordionSummary>
  )
}

export default WorksheetAccordionSummary
