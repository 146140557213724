// hooks
import { useMemo } from 'react'
import { useAppSelector } from '../../redux/hooks'
import { useLanguage } from 'src/hooks/languages'

// mui
import { Button, ButtonGroup, Stack, Tooltip, Typography } from '@mui/material'

// types
import { FC } from 'react'
import { InputElement, StyleElement } from '../../types/redux/layout'
import { MarkSelection } from '../../types/marks'

// assets
import { isNull } from 'lodash'

interface CommentingDialogButtonsProps {
  edit: boolean
  handleSave: (refresh: boolean) => void
  preview?: boolean
  markSelection?: MarkSelection
}

const CommentingDialogButtons: FC<CommentingDialogButtonsProps> = ({
  edit,
  handleSave,
  preview,
  markSelection,
}) => {
  const texts = useLanguage()

  const appearance = useAppSelector((state) => state.appearance)
  const selReq = useAppSelector((state) => !isNull(state.dimensions))
  const errors = useAppSelector((state) => {
    return {
      inputError: state.layout
        .filter(
          (e: InputElement | StyleElement): e is InputElement => 'dbCol' in e
        )
        .map((e) => e.inputErrors.required || e.inputErrors.type)
        .some((b) => b),
      markSelectionError: selReq
        ? (!markSelection?.worksheet ||
            (state.dimensions?.find(
              (wsDims) => wsDims.worksheet === markSelection?.worksheet
            )?.dimensions?.length || 0) > 0) &&
          markSelection?.marks.length === 0
        : false,
    } as { [key: string]: boolean }
  })
  const error = useMemo(
    () =>
      Object.keys(errors)
        .map((key) => errors[key])
        .some((err) => err),
    [errors]
  )

  const ErrorTooltip = ({ children }: { children: JSX.Element }) =>
    errors.inputError || errors.markSelectionError ? (
      <Tooltip
        title={
          <>
            {errors.inputError && (
              <Typography variant='body2'>
                {texts.tooltipSaveCommentFormStillContainsErrors}
              </Typography>
            )}
            {errors.markSelectionError && (
              <Typography variant='body2'>
                {texts.tooltipSaveCommentNoValidMarkSelected}
              </Typography>
            )}
            {error && ''}
          </>
        }
      >
        <span>{children}</span>
      </Tooltip>
    ) : (
      <>{children}</>
    )

  const variant = appearance.dialogButtonsVariant || 'contained'

  if (appearance.dialogButtonsGrouped)
    return (
      <ErrorTooltip>
        <ButtonGroup variant={variant}>
          {appearance.popupDialog && (
            <Button
              color='cancel'
              onClick={() =>
                preview ? {} : tableau.extensions.ui.closeDialog('cancel')
              }
            >
              {texts.buttonTextCancel}
            </Button>
          )}
          <Button
            color='save'
            onClick={() => handleSave(false)}
            disabled={error}
          >
            {edit ? texts.buttonTextUpdate : texts.buttonTextSave}
          </Button>
          <Button
            color='refresh'
            onClick={() => handleSave(true)}
            disabled={error}
          >
            {edit ? texts.buttonTextUpdate : texts.buttonTextSave} &{' '}
            {texts.buttonTextRefresh}
          </Button>
        </ButtonGroup>
      </ErrorTooltip>
    )
  else
    return (
      <Stack direction='row' spacing={2} justifyContent='flex-end'>
        {appearance.popupDialog && (
          <Button
            color='cancel'
            onClick={() =>
              preview ? {} : tableau.extensions.ui.closeDialog('cancel')
            }
            variant={variant}
          >
            {texts.buttonTextCancel}
          </Button>
        )}
        <ErrorTooltip>
          <Button
            color='save'
            onClick={() => handleSave(false)}
            disabled={error}
            variant={variant}
          >
            {edit ? texts.buttonTextUpdate : texts.buttonTextSave}
          </Button>
        </ErrorTooltip>
        <ErrorTooltip>
          <Button
            color='refresh'
            onClick={() => handleSave(true)}
            disabled={error}
            variant={variant}
          >
            {edit ? texts.buttonTextUpdate : texts.buttonTextSave} &{' '}
            {texts.buttonTextRefresh}
          </Button>
        </ErrorTooltip>
      </Stack>
    )
}

export default CommentingDialogButtons
