// hooks
import { useAppSelector } from '../../redux/hooks'

// mui
import { Stack, Button } from '@mui/material'

// types
import { MarkSelection } from '../../types/marks'
import { FC } from 'react'

// redux initial states
import { initialAppearance } from '../../redux/appearance'

interface DialogButtonProps {
  markSelection: MarkSelection
  openDialog: () => void
}

const DialogButton: FC<DialogButtonProps> = ({ markSelection, openDialog }) => {
  const appearance = useAppSelector(
    (state) => state.config.appearance || initialAppearance
  )
  const dimensions = useAppSelector((state) => state.config.dimensions)

  return (
    <Stack direction='column' spacing={2}>
      <Stack alignItems='center'>
        <Button
          variant={appearance.dashboardButtonVariant}
          color={markSelection.commentUids.length === 0 ? 'add' : 'edit'}
          disabled={
            !!dimensions &&
            dimensions.length > 0 &&
            (markSelection.marks.length === 0 ||
              dimensions.findIndex(
                (wsDims) => wsDims.worksheet === markSelection.worksheet
              ) === -1)
          }
          onClick={openDialog}
          sx={{ textTransform: 'none' }}
        >
          {markSelection.commentUids.length === 0
            ? appearance.addCommentText
            : appearance.editCommentText}
        </Button>
      </Stack>
    </Stack>
  )
}

export default DialogButton
