// hooks
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from './redux/hooks'
import { useLanguage } from './hooks/languages'
import useNotifier from './hooks/snackbars'

// css
import './App.css'
import './index.css'

// mui
import {
  Backdrop,
  CircularProgress,
  Typography,
  Stack,
  CssBaseline,
} from '@mui/material'
import { ThemeProvider } from '@mui/system'

// components
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Settings from './components/settings'
import Dialog from './components/dialog'
import TableauDashboardElement from './components/dashboardObject'
import { Fragment } from 'react'

// types
import { Theme } from '@mui/material'
import { BufferType } from './types/redux/buffer'

// redux-actions
import { configurationActions } from './redux/config'

// assets
import { createThemeFromApperance } from './assets/helpers/mui'
import { initialAppearance } from './redux/appearance'

const App = () => {
  useNotifier()
  const texts = useLanguage()

  const dispatch = useAppDispatch()

  const buffer = useAppSelector((state) => state.buffer)
  const configChanged = useAppSelector((state) => state.config.changed)
  const appliedAppearance = useAppSelector(
    (state) => state.config.appearance
  )
  const appearance = useAppSelector((state) => state.appearance)
  const layout = useAppSelector((state) => state.layout)
  const dimensions = useAppSelector((state) => state.dimensions)

  const [theme, setTheme] = useState(
    createThemeFromApperance(initialAppearance)
  )

  useEffect(() => {
    setTheme(
      createThemeFromApperance(configChanged ? appearance : appliedAppearance)
    )
  }, [setTheme, configChanged, appearance, appliedAppearance])

  useEffect(() => {
    dispatch(configurationActions.setAppearanceChanged(appearance))
  }, [dispatch, appearance])

  useEffect(() => {
    dispatch(configurationActions.setLayoutChanged(layout))
  }, [dispatch, layout])

  useEffect(() => {
    dispatch(configurationActions.setDimensionsChanged(dimensions))
  }, [dispatch, dimensions])

  const getBufferText = (b: BufferType) => {
    switch (b) {
      case 'databaseCheck':
        return texts.bufferDatabaseCheck
      case 'usernameCheck':
        return texts.bufferUsernameCheck
      case 'tableauCheck':
        return texts.bufferTableauCheck
      case 'savingComment':
        return texts.bufferSavingComment
      case 'savingConfig':
        return texts.bufferSavingConfig
      case 'fetchingTableauData':
        return texts.bufferFetchingTableauData
      case 'fetchingSavedFields':
        return texts.bufferFetchingSavedFields
      case 'fetchingSiblingsData':
        return texts.bufferFetchingSiblings
    }
  }

  // useEffect(() => {
  //   console.log('buffer', buffer)
  // }, [buffer])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Backdrop
        open={Object.values(buffer).some((b) => b)}
        sx={{
          color: '#fff',
          zIndex: (theme: Theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Stack justifyContent='center' alignItems='center'>
          <CircularProgress color='inherit' />
          {Object.entries(buffer).map(([key, val], idx) =>
            val ? (
              <Typography key={idx}>
                {getBufferText(key as BufferType)}
              </Typography>
            ) : (
              <Fragment key={idx}></Fragment>
            )
          )}
        </Stack>
      </Backdrop>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<TableauDashboardElement />} />
          <Route path='/dialog' element={<Dialog />} />
          <Route path='/settings' element={<Settings />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
