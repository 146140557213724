// mui
import { TextField } from '@mui/material'

// types
import { TextfieldElement, Value } from '../../types/redux/layout'
import { FC } from 'react'

// assets
import { textFieldStyleProps } from '../../assets/helpers/mui'
import { useLanguage } from 'src/hooks/languages'

interface TextfieldInputElementProps {
  element: TextfieldElement
  value: Value
  onChange: any
  autoFocus?: boolean
}

const TextfieldInputElement: FC<TextfieldInputElementProps> = ({
  element,
  value,
  onChange,
  autoFocus,
}) => {
  const texts = useLanguage()

  return (
    <TextField
      autoFocus={!!autoFocus}
      {...{ ...textFieldStyleProps(element.label), onChange }}
      // focused={expanded === idx}
      error={element.inputErrors.required}
      helperText={
        element.inputErrors.required ? texts.formHelperFieldRequired : ''
      }
      multiline={element.multirow}
      maxRows={
        element.multirow && !element.fixedRows && element.rows
          ? Number(element.rows)
          : undefined
      }
      rows={
        element.multirow && element.fixedRows && element.rows
          ? Number(element.rows)
          : undefined
      }
      value={value || ''}
    />
  )
}

export default TextfieldInputElement
