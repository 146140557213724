import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { configurationActions } from './config'
import { thief } from './configThunks'

// mui
import * as locales from '@mui/material/locale'
import {
  AppearanceState,
  OptionsButtonVariant,
} from 'src/types/redux/appearance'

export const localeOptions = Object.keys(locales)

export const coorperatePrimary = '#0096DC'
export const coorperateSecondary = '#004663'
export const coorperateWarning = '#EBE117'
export const coorperateError = '#EB1733'
export const coorperateSuccess = '#109E2D'

export const initialAppearance: AppearanceState = {
  popupDialog: true,
  dashboardButtonVariant: OptionsButtonVariant.contained,
  addCommentText: 'Add Comment',
  editCommentText: 'Edit Comment',
  addCommentColor: coorperatePrimary,
  editCommentColor: coorperateSecondary,
  dialogButtonsGrouped: false,
  dialogButtonsVariant: OptionsButtonVariant.contained,
  dialogCancelColor: coorperateWarning,
  dialogSaveColor: coorperateSecondary,
  dialogRefreshColor: coorperatePrimary,
  locale: 'enUS',
  darkMode: false,
}

const appearanceSlice = createSlice({
  name: 'appearance',
  initialState: initialAppearance,
  reducers: {
    set: (_, action: PayloadAction<AppearanceState>) =>
      JSON.parse(JSON.stringify(action.payload)) as AppearanceState,
    update: (state, action: PayloadAction<Partial<AppearanceState>>) => {
      return { ...state, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      configurationActions.set,
      (state, action) =>
        ((action.payload.appearance &&
          JSON.parse(
            JSON.stringify(action.payload.appearance)
          )) as AppearanceState) || state
    )
    builder.addCase(configurationActions.discardChanges, (_, action) => {
      return (
        (action.payload.appearance &&
          JSON.parse(JSON.stringify(action.payload.appearance))) ||
        JSON.parse(JSON.stringify(initialAppearance))
      )
    })
    builder.addCase(thief.fulfilled, (state, action) => {
      if (action.payload.s === 'Appearance')
        return JSON.parse(
          JSON.stringify(action.payload.state)
        ) as AppearanceState
      return state
    })
  },
})

export const appearanceActions = appearanceSlice.actions

export default appearanceSlice.reducer
