// mui
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material'

// types
import { RadiogroupElement, Value } from '../../types/redux/layout'
import { FC } from 'react'
import { useLanguage } from 'src/hooks/languages'

// assets

interface RadioInputElementProps {
  element: RadiogroupElement
  value: Value
  onChange: any
  autoFocus?: boolean
}

const RadioInputElement: FC<RadioInputElementProps> = ({
  element,
  value,
  onChange,
  autoFocus,
}) => {
  const texts = useLanguage()

  return (
    <FormControl component='fieldset' variant='standard'>
      <FormLabel component='legend' error={element.inputErrors.required}>
        {element.label}
      </FormLabel>
      <RadioGroup
        row={element.direction === 'row'}
        value={value || ''}
        onChange={onChange}
      >
        {element.labels!.map((label, i) => (
          <FormControlLabel
            key={i}
            sx={{ alignSelf: 'flex-start' }}
            labelPlacement={element.labelPlacement}
            value={label}
            control={
              <Radio
                inputProps={{
                  autoFocus: i === 0 && !!autoFocus,
                }}
                color={
                  element.color! as
                    | 'primary'
                    | 'secondary'
                    | 'error'
                    | 'warning'
                    | 'info'
                    | 'success'
                    | 'default'
                    | undefined
                }
              />
            }
            label={label}
          />
        ))}
      </RadioGroup>
      <FormHelperText error={element.inputErrors.required}>
        {element.labels &&
          element.labels.length > 0 &&
          element.inputErrors.required &&
          texts.formHelperFieldRequired}
      </FormHelperText>
    </FormControl>
  )
}

export default RadioInputElement
