// hooks
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { useLanguage } from 'src/hooks/languages'

// mui
import '@mui/lab'
import { Stack, FormControlLabel, Checkbox } from '@mui/material'

// redux
import { layoutActions } from '../../../../redux/layout'

// types
import { ChangeEvent, FC } from 'react'
import { InputElement } from '../../../../types/redux/layout'

interface DatepickerElementSettingsProps {
  idx: number
}

const DatepickerElementSettings: FC<DatepickerElementSettingsProps> = ({
  idx,
}) => {
  const texts = useLanguage()
  const dispatch = useAppDispatch()

  const element = useAppSelector((state) => state.layout[idx] as InputElement)

  return (
    <Stack direction='column' spacing={2}>
      <FormControlLabel
        key={idx}
        control={
          <Checkbox
            checked={!!element.preselectCurrentDate}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              dispatch(
                layoutActions.togglePreselectCurrentDate({
                  idx,
                  preselectCurrentDate: event.target.checked,
                })
              )
            }
          />
        }
        label={texts.inputLabelDatepickerPreselectCurrentDate}
      />
    </Stack>
  )
}

export default DatepickerElementSettings
