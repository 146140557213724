// hooks
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'

// mui
import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  Delete,
  VisibilityOff,
  Visibility,
} from '@mui/icons-material'
import { AccordionSummary, Stack, Chip, IconButton } from '@mui/material'

// types
import { FC, MouseEvent } from 'react'
import { InputType, StyleType } from '../../../types/redux/layout'

// redux-actions
import { layoutActions } from '../../../redux/layout'

// assets
import { inputTypeIcons, styleTypeIcons } from '../../../assets/settings'

interface ElementAccordionSummaryProps {
  idx: number
}

const ElementAccordionSummary: FC<ElementAccordionSummaryProps> = ({ idx }) => {
  const dispatch = useAppDispatch()

  const layout = useAppSelector((state) => state.layout)
  const element = useAppSelector((state) => state.layout[idx])
  const savedLayout = useAppSelector((state) => state.config.layout)

  return (
    <AccordionSummary
      sx={{ width: '100%' }}
      aria-controls='accordion-summary'
      id='accordion-summary'
    >
      <Stack
        direction='row'
        spacing={2}
        alignItems='center'
        justifyContent='space-between'
        width='stretch'
      >
        <Chip
          icon={
            'dbCol' in element
              ? inputTypeIcons[element.type as InputType]
              : styleTypeIcons[element.type as StyleType]
          }
          label={'dbCol' in element ? element.dbCol : element.identifier}
          color={'dbCol' in element ? 'primary' : 'secondary'}
          sx={{ cursor: 'pointer' }}
        />
        <Stack direction='row' alignSelf='flex-end'>
          <IconButton
            color={'dbCol' in element ? 'primary' : 'secondary'}
            sx={{ pointerEvents: 'auto' }}
            onClick={(event: MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation()
              dispatch(
                layoutActions.toggleVisibility({
                  idx,
                  visible: !element.visible,
                })
              )
            }}
          >
            {element.visible ? <Visibility /> : <VisibilityOff />}
          </IconButton>
          <IconButton
            color={'dbCol' in element ? 'primary' : 'secondary'}
            sx={{ pointerEvents: 'auto' }}
            disabled={idx === 0}
            onClick={(event: MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation()
              dispatch(
                layoutActions.moveElement({
                  idx,
                  dir: -1 as 1 | -1,
                })
              )
            }}
          >
            <KeyboardArrowUp />
          </IconButton>
          <IconButton
            color={'dbCol' in element ? 'primary' : 'secondary'}
            sx={{ pointerEvents: 'auto' }}
            disabled={idx === layout.length - 1}
            onClick={(event: MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation()
              dispatch(
                layoutActions.moveElement({
                  idx,
                  dir: 1 as 1 | -1,
                })
              )
            }}
          >
            <KeyboardArrowDown />
          </IconButton>
          <IconButton
            color='error'
            sx={{ pointerEvents: 'auto' }}
            disabled={
              ('dbCol' in element &&
                layout.findIndex((el) => el.dependsOn === idx) > -1) ||
              ('dbCol' in element &&
                savedLayout &&
                savedLayout.findIndex(
                  (e) => 'dbCol' in e && e.dbCol === element.dbCol
                ) > -1 &&
                !element.configErrors.dbColDuplicate)
            }
            onClick={(event: MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation()
              dispatch(layoutActions.deleteElement(idx))
            }}
          >
            <Delete />
          </IconButton>
        </Stack>
      </Stack>
    </AccordionSummary>
  )
}

export default ElementAccordionSummary
