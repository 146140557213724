// hooks
import { useLanguage } from 'src/hooks/languages'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'

// mui
import { Paper, Stack } from '@mui/material'
import { Box } from '@mui/system'

// components
import CommentingDialogButtons from '../dialog/CommentingDialogButtons'
import LayoutView from '../form'

// types
import { MarkSelection } from '../../types/marks'
import { FC } from 'react'

// thunks
import { saveComment } from '../../redux/commentThunks'

// redux actions
import { snackbarsActions } from 'src/redux/snackbars'

interface DashboardFormProps {
  markSelection: MarkSelection
  refreshCommentDataSources: () => Promise<void>
}

const DashboardForm: FC<DashboardFormProps> = ({
  markSelection,
  refreshCommentDataSources,
}) => {
  const texts = useLanguage()

  const dispatch = useAppDispatch()

  const username = useAppSelector((state) => state.tableau.username.name)

  const handleSave = (refresh: boolean) => {
    if (username)
      dispatch(
        saveComment({
          username,
          markSelection,
          commentUids: markSelection.commentUids,
        })
      )
        .unwrap()
        .then((response) => {
          response.success && refresh && refreshCommentDataSources()
        })
    else
      dispatch(
        snackbarsActions.enqeue({
          customMessage: texts.snackbarUsernameMissing,
          options: { variant: 'error' },
        })
      )
  }

  return (
    <Box height='100%' overflow='hidden'>
      <Paper
        sx={{
          height: 'stretch',
          width: 'stretch',
          margin: 2,
          padding: 2,
        }}
      >
        <Stack
          height='stretch'
          direction='column'
          spacing={2}
          justifyContent='space-between'
        >
          <LayoutView />
          <CommentingDialogButtons
            {...{
              edit: markSelection.commentUids.length > 0,
              handleSave,
              markSelection,
            }}
          />
        </Stack>
      </Paper>
    </Box>
  )
}

export default DashboardForm
