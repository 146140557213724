// redux-toolkit
import { createSlice } from '@reduxjs/toolkit'

// thunks
import { getSavedFields, getCommentData } from './commentThunks'
import { thief } from './configThunks'

// actions
import { configurationActions } from './config'

// types
import {
  InputConfigErrors,
  InputErrors,
  InputType,
  Value,
  InputCore,
  TextfieldElement,
  CheckboxSwitchElement,
  DropdownElement,
  RatingElement,
  DatepickerElement,
  RadiogroupElement,
  InputElement,
  initialStyleConfigErrors,
  StyleCore,
  StyleElement,
  StyleType,
  LayoutState,
} from 'src/types/redux/layout'
import { PayloadAction } from '@reduxjs/toolkit'

// assets
import { inputTypes } from '../assets/settings'
import dayjs from 'dayjs'

export const initialInputConfigErrors: InputConfigErrors = {
  dbColMissing: true,
  dbColDuplicate: false,
  dependence: false,
}

export const initialInputErrors: InputErrors = {
  required: false,
  type: false,
}

export const defaultValue = (type: InputType): Value =>
  type === 'Datepicker' || type === 'Rating'
    ? null
    : type === 'Checkbox' || type === 'Switch'
    ? false
    : ''

const initialInputCore = (type: InputType): InputCore => {
  return {
    type,
    visible: true,
    dataType:
      type === 'Datepicker'
        ? 'datetime?'
        : type === 'Rating'
        ? 'number?'
        : type === 'Checkbox' || type === 'Switch'
        ? 'boolean?'
        : 'string?',
    default: defaultValue(type),
    dbCol: '',
    label: '',
    required: false,
    dependsOn: -1,
    configErrors: initialInputConfigErrors,
    value:
      type === 'Datepicker' || type === 'Rating'
        ? null
        : type === 'Checkbox' || type === 'Switch'
        ? false
        : '',
    inputErrors: initialInputErrors,
  }
}

export const initialTextfield: TextfieldElement = {
  ...initialInputCore('Textfield'),
  multirow: false,
  rows: undefined,
  fixedRows: false,
}

export const initialCheckbox: CheckboxSwitchElement = {
  ...initialInputCore('Checkbox'),
  labelPlacement: 'end',
  color: 'primary',
}

export const initialSwitch: CheckboxSwitchElement = {
  ...initialInputCore('Switch'),
  labelPlacement: 'end',
  color: 'primary',
}

export const initialDropdown: DropdownElement = {
  ...initialInputCore('Dropdown'),
  options: [],
}

export const initialRating: RatingElement = {
  ...initialInputCore('Rating'),
  precision: 1,
  labelPlacement: 'top',
}

export const initialDatepicker: DatepickerElement = {
  ...initialInputCore('Datepicker'),
  preselectCurrentDate: false,
}

export const initialRadiogroup: RadiogroupElement = {
  ...initialInputCore('Radiogroup'),
  labels: [],
  color: 'primary',
  direction: 'column',
  labelPlacement: 'end',
}

const initialStyleCore = (type: StyleType): StyleCore => {
  return {
    type,
    visible: true,
    identifier: '',
    dependsOn: -1,
    configErrors: initialStyleConfigErrors,
  }
}

export const initialText: StyleElement = {
  ...initialStyleCore('Text'),
  content: '',
  textType: 'heading',
  size: 4,
  alignment: 'left',
}

export const initialDivider: StyleElement = {
  ...initialStyleCore('Divider'),
  text: false,
  chip: false,
  color: 'primary',
  alignment: 'left',
}

const inputTypeInitialMatch: { [key in InputType]: InputElement } = {
  Textfield: initialTextfield,
  Dropdown: initialDropdown,
  Radiogroup: initialRadiogroup,
  Rating: initialRating,
  Datepicker: initialDatepicker,
  Switch: initialSwitch,
  Checkbox: initialCheckbox,
}

const styleTypeInitialMatch: { [key in StyleType]: StyleElement } = {
  Text: initialText,
  Divider: initialDivider,
}

export const initialLayout: LayoutState = []

export const layoutSlice = createSlice({
  name: 'layout',
  initialState: initialLayout,
  reducers: {
    set: (_, action: PayloadAction<LayoutState>) =>
      action.payload.slice().map((el) =>
        inputTypes.findIndex((t) => t === el.type) > -1
          ? ({
              ...el,
              configErrors: initialInputConfigErrors,
            } as InputElement)
          : ({
              ...el,
              configErrors: initialStyleConfigErrors,
            } as StyleElement)
      ),
    addInput: (state) => {
      return [...state, initialTextfield]
    },
    updateInputType: (
      state,
      action: PayloadAction<{ idx: number; type: InputType }>
    ) => {
      const element = state[action.payload.idx] as InputElement
      state[action.payload.idx] = {
        ...inputTypeInitialMatch[action.payload.type],
        dbCol: element.dbCol,
        label: element.label,
        configErrors: { ...element.configErrors },
      }
      return state
    },
    setDbCol: (
      state,
      action: PayloadAction<{ idx: number; dbCol: string }>
    ) => {
      let element = state[action.payload.idx] as InputElement
      element.dbCol = action.payload.dbCol
      element.configErrors.dbColMissing = !action.payload.dbCol
      element.configErrors.dbColDuplicate =
        state.findIndex(
          (el, i) =>
            i !== action.payload.idx &&
            'dbCol' in el &&
            el.dbCol === action.payload.dbCol
        ) > -1
      state.splice(action.payload.idx, 1, element)
      return state
    },
    addStyle: (state) => [...state, initialText],
    updateStyleType: (
      state,
      action: PayloadAction<{ idx: number; type: StyleType }>
    ) => {
      const element = state[action.payload.idx] as StyleElement
      state[action.payload.idx] = {
        ...styleTypeInitialMatch[action.payload.type],
        identifier: element.identifier,
        configErrors: { ...element.configErrors },
      }
      return state
    },
    deleteElement: (state, action: PayloadAction<number>) => {
      state.splice(action.payload, 1)
      return state
    },
    moveElement: (
      state,
      action: PayloadAction<{ idx: number; dir: -1 | 1 }>
    ) => {
      if (
        !(action.payload.idx === 0 && action.payload.dir === -1) &&
        !(action.payload.idx === state.length - 1 && action.payload.dir === 1)
      ) {
        // increasing dependsOn elements
        const incDepIdc = state
          .map((e, idx) =>
            (e.dependsOn === action.payload.idx && action.payload.dir === 1) ||
            (e.dependsOn === action.payload.idx - 1 &&
              action.payload.dir === -1)
              ? idx
              : -1
          )
          .filter((idx) => idx > -1)
        incDepIdc.forEach((idx) => {
          state[idx] = {
            ...state[idx],
            dependsOn: state[idx].dependsOn + 1,
          }
        })
        // decreasing dependsOn elements
        const decDepIdc = state
          .map((e, idx) =>
            ((e.dependsOn === action.payload.idx &&
              action.payload.dir === -1) ||
              (e.dependsOn === action.payload.idx + 1 &&
                action.payload.dir === 1)) &&
            incDepIdc.indexOf(idx) === -1
              ? idx
              : -1
          )
          .filter((idx) => idx > -1)
        decDepIdc.forEach((idx) => {
          state[idx] = {
            ...state[idx],
            dependsOn: state[idx].dependsOn - 1,
          }
        })

        state.splice(
          action.payload.idx + action.payload.dir,
          0,
          state.splice(action.payload.idx, 1)[0]
        )
      }
      return state
    },
    updateInput: (
      state,
      action: PayloadAction<{ idx: number; options: Partial<InputElement> }>
    ) => {
      const { idx, options } = action.payload
      const element = state[idx] as InputElement
      state[idx] = {
        ...element,
        ...options,
        value:
          'default' in options ? options.default : defaultValue(element.type),
        inputErrors: {
          ...element.inputErrors,
          required: options.required ? !element.value : false,
        },
      }
      return state
    },
    togglePreselectCurrentDate: (
      state,
      action: PayloadAction<{ idx: number; preselectCurrentDate: boolean }>
    ) => {
      const { idx, preselectCurrentDate } = action.payload
      const element = state[idx] as InputElement
      const value = preselectCurrentDate ? dayjs() : element.default || null
      state[idx] = { ...element, preselectCurrentDate, value, default: value }
      return state
    },
    updateStyle: (
      state,
      action: PayloadAction<{ idx: number; options: Partial<StyleElement> }>
    ) => {
      state[action.payload.idx] = {
        ...state[action.payload.idx],
        ...action.payload.options,
      } as StyleElement
      return state
    },
    updateValue: (
      state,
      action: PayloadAction<{ idx: number; value: Value }>
    ) => {
      const { idx, value } = action.payload
      const element = state[idx] as InputElement
      const dependenceIdc = state
        .map((e, i) => (e.dependsOn === idx && 'dbCol' in e ? i : -1))
        .filter((i) => i > -1)
      dependenceIdc.forEach(
        (i) =>
          (state[i] = {
            ...(state[i] as InputElement),
            value: (state[i] as InputElement).default,
          })
      )
      state[idx] = {
        ...element,
        value,
        inputErrors: {
          ...element.inputErrors,
          required: element.required && !value,
        },
      }
      return state
    },
    updateDependsOn: (
      state,
      action: PayloadAction<{ idx: number; dependsOn: number }>
    ) => {
      const { idx, dependsOn } = action.payload
      state[idx] = {
        ...state[idx],
        dependsOn,
        enablingValue: (state[dependsOn] as InputElement)?.default,
      }
      return state
    },
    updateEnablingValue: (
      state,
      action: PayloadAction<{ idx: number; enablingValue: Value }>
    ) => {
      const { idx, enablingValue } = action.payload
      state[idx] = {
        ...state[idx],
        enablingValue,
      }
      return state
    },
    toggleVisibility: (
      state,
      action: PayloadAction<{ idx: number; visible: boolean }>
    ) => {
      const { idx, visible } = action.payload
      state[idx] = { ...state[idx], visible }
      return state
    },
    reset: (state) =>
      state.map((e) => ('dbCol' in e ? { ...e, value: e.default } : e)),
  },
  extraReducers: (builder) => {
    builder.addCase(
      configurationActions.set,
      (state, action) => action.payload.layout?.slice() || state
    )
    builder.addCase(
      configurationActions.discardChanges,
      (_, action) => action.payload.layout?.slice() || initialLayout.slice()
    )
    builder.addCase(
      getSavedFields.fulfilled,
      (_, action) => action.payload.layout
    )
    builder.addCase(
      getCommentData.fulfilled,
      (_, action) => action.payload.layout
    )
    builder.addCase(thief.fulfilled, (state, action) => {
      if (action.payload.s === 'Layout')
        return JSON.parse(JSON.stringify(action.payload.state)) as LayoutState
      return state
    })
  },
})

export const layoutActions = layoutSlice.actions

export default layoutSlice.reducer
