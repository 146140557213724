// hooks
import { useAppSelector } from 'src/redux/hooks'
import { useLanguage } from 'src/hooks/languages'

// mui
import {
  Abc,
  AbcOutlined,
  CalendarToday,
  CalendarTodayOutlined,
  Numbers,
  NumbersOutlined,
  ToggleOff,
  ToggleOffOutlined,
} from '@mui/icons-material'
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Toolbar,
  Typography,
} from '@mui/material'

// types
import { FC } from 'react'
import { DataType, InputElement, StyleElement } from 'src/types/redux/layout'

// assets
import { uniq } from 'lodash'

type CatRowData = {
  type: DataType
  name: string
}

const DimensionsPreview: FC = () => {
  const texts = useLanguage()

  const configName = useAppSelector((state) => state.config.name)
  const inputs = useAppSelector((state) =>
    state.layout
      .filter(
        (e: InputElement | StyleElement): e is InputElement => 'dbCol' in e
      )
      .map((e) => {
        return { type: e.dataType, name: e.dbCol } as CatRowData
      })
  )
  const dimensions = useAppSelector((state) =>
    uniq(state.dimensions?.map((wsds) => wsds.dimensions).flat()).map(
      (name) => {
        return { type: 'string', name } as CatRowData
      }
    )
  )

  const metadata = [
    ['number', 'Comment Uid'],
    ['number', 'Parent Uid'],
    ['number', 'Siblings Uid'],
    ['string', 'Created By'],
    ['number', 'Created At'],
  ].map(([type, name]) => {
    return { type, name } as CatRowData
  })

  const iconFromType = (t: DataType) => {
    switch (t) {
      case 'boolean':
        return <ToggleOff />
      case 'boolean?':
        return <ToggleOffOutlined />
      case 'string':
        return <Abc />
      case 'string?':
        return <AbcOutlined />
      case 'number':
        return <Numbers />
      case 'number?':
        return <NumbersOutlined />
      case 'datetime':
        return <CalendarToday />
      case 'datetime?':
        return <CalendarTodayOutlined />
    }
  }

  const makeTableRows = (rowContent: CatRowData[], category: string) =>
    rowContent.map(({ type, name }, idx) => (
      <TableRow key={idx}>
        {idx === 0 ? (
          <TableCell rowSpan={rowContent.length}>{category}</TableCell>
        ) : (
          <></>
        )}
        <TableCell>{iconFromType(type)}</TableCell>
        <TableCell>{name}</TableCell>
      </TableRow>
    ))

  return (
    <Paper>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant='h6'
          id='tableTitle'
          component='div'
        >
          {`cb_comments_${configName} & cb_history_${configName}`}
        </Typography>
        {/* <Tooltip title='Filter list'>
          <IconButton>
            <FilterList />
          </IconButton>
        </Tooltip> */}
      </Toolbar>
      <TableContainer>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>{texts.tableHeadCategory}</TableCell>
              <TableCell>{texts.tableHeadDataType}</TableCell>
              <TableCell>{texts.tableHeadColumnName}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {makeTableRows(metadata, 'Metadata')}

            {makeTableRows(inputs, 'Inputs')}

            {makeTableRows(dimensions, 'Dimensions')}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default DimensionsPreview
