// hooks
import { useEffect, useState } from 'react'
import { useAppDispatch } from '../../../redux/hooks'
import { useLanguage } from 'src/hooks/languages'

// components
import { Fragment } from 'react'

// mui
import {
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  MenuItem,
  Typography,
} from '@mui/material'

// types
import { ChangeEvent, SetStateAction, FC } from 'react'

// redux-thunks
import { loadConfigs, thief } from '../../../redux/configThunks'

// assets
import { STabs } from '..'
import { textFieldStyleProps } from '../../../assets/helpers/mui'

interface ThiefProps {
  s: STabs
  openThief: boolean
  setOpenThief: (value: SetStateAction<boolean>) => void
}

const Thief: FC<ThiefProps> = ({ s, openThief, setOpenThief }) => {
  const texts = useLanguage()

  const dispatch = useAppDispatch()

  const [configName, setConfigName] = useState<string>('')
  const [avConfigs, setAvConfigs] = useState<string[]>([])

  const handleClose = () => {
    setOpenThief(false)
    setConfigName('')
  }

  // create thunk for this!
  useEffect(() => {
    if (openThief)
      dispatch(loadConfigs())
        .unwrap()
        .then((configs) => setAvConfigs(configs))
  }, [dispatch, openThief])

  const handleLoad = () => {
    dispatch(thief({ s, configName }))
      .unwrap()
      .then((_) => setOpenThief(false))
  }

  const getTabLabel = () => {
    switch (s) {
      case 'Appearance':
        return texts.tabLabelAppearance
      case 'Layout':
        return texts.tabLabelLayout
      case 'Dimensions':
        return texts.tabLabelDimensions
      default:
        return ''
    }
  }

  return (
    <Dialog fullWidth maxWidth='xs' open={openThief} onClose={handleClose}>
      <DialogTitle>{`${getTabLabel()} ${texts.dialogTitleThief}`}</DialogTitle>
      <Fragment>
        <DialogContent>
          <Typography variant='body2'>{texts.dialogContentThief}</Typography>
          <Stack padding={2}>
            <TextField
              {...textFieldStyleProps(texts.inputLabelSavedConfigs)}
              select
              value={configName || ''}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setConfigName(event.target.value as string)
              }
            >
              {avConfigs.map((s) => (
                <MenuItem key={s} value={s}>
                  {s}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={handleClose} autoFocus>
            {texts.buttonTextCancel}
          </Button>
          <Button variant='outlined' color='success' onClick={handleLoad}>
            {texts.buttonTextLoad}
          </Button>
        </DialogActions>
      </Fragment>
    </Dialog>
  )
}

export default Thief
