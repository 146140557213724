// hooks
import { useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { useKeyPress } from '../../hooks/keypress'
import { useLanguage } from 'src/hooks/languages'

// components
import DialogAppBar from './DialogAppBar'
import LayoutView from '../form'
import DimensionsTable from './DimensionsTable'

// mui
import {
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from '@mui/material'

// redux-thunks
import { getSavedFields, saveComment, getCommentData } from '../../redux/commentThunks'

// redux-actions
import { configurationActions } from '../../redux/config'
import { snackbarsActions } from 'src/redux/snackbars'
import { tableauActions } from 'src/redux/tableau'

// types
import { MarkSelection } from '../../types/marks'
import { ConfigState } from '../../types/redux/config'
import { TableauState } from 'src/types/redux/tableau'
import { bufferActions } from 'src/redux/buffer'
import { Value } from '../../types/redux/layout'

const CommentDialog = () => {
  const texts = useLanguage()

  const dispatch = useAppDispatch()

  const username = useAppSelector((state) => state.tableau.username.name)

  const [edit, setEdit] = useState(false)
  const [markSelection, setMarkSelection] = useState<MarkSelection>({
    worksheet: '',
    dimensions: [],
    marks: [],
    commentUids: [],
  })
  const [selectedCommentUids, setSelectedCommentUids] = useState<number[]>([])
  const [expanded, setExpanded] = useState('fields-panel')
  const [commentUid, setCommentUid] = useState<number>()
  const [siblingsUid, setSiblingsUid] = useState<number>()

  const [siblings, setSiblings] = useState<{ [key: string]: Value; commentUid: number }[]>([])

  const escapePressed = useKeyPress('Escape')
  const ctrlEnterPressed = useKeyPress('Enter', ['ctrlKey'])
  const ctrlShiftEnterPressed = useKeyPress('Enter', ['ctrlKey', 'shiftKey'])

  const handleSave = useCallback(
    (refresh: boolean) => {
      console.log('username', username)
      if (!!username)
        dispatch(
          saveComment({
            username,
            markSelection,
            commentUids: selectedCommentUids,
          })
        )
          .unwrap()
          .then(
            (response) =>
              response.success &&
              tableau.extensions.ui.closeDialog(refresh ? 'refresh' : '')
          )
      else
        dispatch(
          snackbarsActions.enqeue({
            customMessage: texts.snackbarUsernameMissing,
            options: { variant: 'error' },
          })
        )
    },
    [
      dispatch,
      username,
      markSelection,
      selectedCommentUids,
      texts.snackbarUsernameMissing,
    ]
  )

  useEffect(() => {
    // press escape to close dialog
    escapePressed && tableau.extensions.ui.closeDialog('cancel')
  }, [escapePressed])

  useEffect(() => {
    // press ctrl & enter to save comment
    ctrlEnterPressed && handleSave(false)
  }, [ctrlEnterPressed, handleSave])

  useEffect(() => {
    // press ctrl & shift & enter to save comment and refresh datasource
    ctrlShiftEnterPressed && handleSave(true)
  }, [ctrlShiftEnterPressed, handleSave])

  useEffect(() => {
    tableau.extensions.initializeDialogAsync().then((payloadJSON: string) => {
      const payload: {
        markSelection: MarkSelection
        config: ConfigState
        tableauInfo: TableauState
      } = JSON.parse(payloadJSON)

      // if (payload.config.dimensions) {
      //   dispatch(bufferActions.activateBuffer('fetchingTableauData'))
      // }

      dispatch(configurationActions.set(payload.config))
      dispatch(tableauActions.set(payload.tableauInfo))
      setMarkSelection(payload.markSelection)
      if (payload.markSelection.commentUids.length === 1)
        setCommentUid(payload.markSelection.commentUids[0])
    })
  }, [dispatch, setCommentUid])

  useEffect(() => {
    if (markSelection.commentUids.length > 0)
      dispatch(
        //getSavedFields
        //getCommentData
        getCommentData({
          commentUid: markSelection.commentUids[0],
          worksheet: markSelection.worksheet,
        })
      )
        .unwrap()
        .then((res) => {
          res.siblingsUid && setSiblingsUid(res.siblingsUid)
          res.siblingsUid && setSiblings(res.siblings)
          console.log("getCommentData -> ", res);
        })
        // .finally(() => dispatch(bufferActions.deactivateBuffer('fetchingTableauData')))
  }, [dispatch, commentUid, markSelection, setSiblingsUid])

  const handleExpand = (panel: string) => setExpanded(panel)

  const editMenu = (siblings: { [key: string]: Value; commentUid: number }[]) => {
    const props = (panel: string) => {
      return {
        expanded: expanded === panel,
        onChange: () => handleExpand(panel),
        sx: { border: expanded === panel ? 1 : 0, borderColor: 'primary.main' },
      }
    }
    if (markSelection.commentUids.length > 0)
      return (
        <>
          <Accordion {...props('dimensions-panel')}>
            <AccordionSummary aria-controls='dimensions-panel'>
              <Typography>{texts.sectionHeaderMarks}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <DimensionsTable
                {...{
                  siblingsUid,
                  siblings,
                  markSelection,
                  selectedCommentUids,
                  setSelectedCommentUids,
                }}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion {...props('fields-panel')}>
            <AccordionSummary aria-controls='fields-panel'>
              <Typography>{texts.sectionHeaderForm}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack direction='column' spacing={2} margin={2}>
                <LayoutView />
              </Stack>
            </AccordionDetails>
          </Accordion>
        </>
      )
    return <p>{texts.paragraphEditCommentNoCommentUid}</p> // ??? when is this visible ???
  }

  useEffect(() => {
    setEdit(
      markSelection.commentUids?.length > 0 && markSelection.commentUids[0] > -1
    )
  }, [markSelection])

  return (
    <>
      <Box height='stretch' overflow='hidden'>
        <Stack direction='column' spacing={2} margin={2}>
          {edit ? editMenu(siblings) : <LayoutView />}
        </Stack>
      </Box>
      <DialogAppBar
        {...{
          handleSave,
          edit,
          selectedCommentUids,
          markSelection,
        }}
      />
    </>
  )
}

export default CommentDialog
