// hooks
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { useState, useEffect, ChangeEvent } from 'react'
import { useLanguage } from 'src/hooks/languages'

// mui
import {
  Stack,
  Button,
  Typography,
  ListItemButton,
  Box,
  List,
  ListItemIcon,
  Toolbar,
  ListItemText,
  Tooltip,
  Drawer,
  ListItem,
  AppBar,
  TextField,
} from '@mui/material'
import { Abc, ViewStream, Brush, Help, Close } from '@mui/icons-material'
import Grid from '@mui/material/Unstable_Grid2'

// components
import DimensionSettings from './dimensions'
import AppearanceSettings from './appearance'
import SetupAppBar from './SettingsAppBar'
import LayoutSettings from './layout'
import CombleauLogo from '../../assets/CombleauLogo'
import ConfigSaveButton from './ConfigSaveButton'

// types
import { FC } from 'react'

// assets
import { ensure } from '../../assets/helpers/typescript'
import { getLocale } from 'src/assets/helpers/tableau'
import { textFieldStyleProps } from 'src/assets/helpers/mui'

// redux-actions
import { configurationActions } from '../../redux/config'
import { appearanceActions } from 'src/redux/appearance'

// combleau version
import packageJSON from '../../../package.json'
import {
  maxTableNameLength,
  restrictTableNameChars,
} from 'src/assets/databases'

const { version } = packageJSON

const drawerWidth = 180

export type STabs = 'Appearance' | 'Layout' | 'Dimensions' | 'Help'

const Settings: FC = () => {
  const texts = useLanguage()

  const dispatch = useAppDispatch()

  const config = useAppSelector((state) => state.config)

  const [s, setS] = useState<STabs>('Appearance')
  const [availableSheets, setAvailableSheets] = useState<string[]>([])

  useEffect(() => {
    tableau.extensions.initializeDialogAsync().then((configJSON: string) => {
      if (configJSON) dispatch(configurationActions.set(JSON.parse(configJSON)))
      // set language correctly
      else dispatch(appearanceActions.update({ locale: getLocale() }))

      // move this to dimensions settings ?
      setAvailableSheets(
        ensure(
          tableau.extensions.dashboardContent?.dashboard.worksheets
            .map((sheet) => sheet.name)
            .filter((name: string) => name !== 'Username')
        )
      )
    })
  }, [dispatch])

  const handleCancel = () => tableau.extensions.ui.closeDialog('')

  const handleChangeTab = (tab: STabs): void => setS(tab)

  return (
    <Stack
      direction='column'
      height='100vh'
      alignItems='stretch'
      overflow='hidden'
    >
      <AppBar
        position='sticky'
        sx={{ zIndex: (t) => t.zIndex.drawer + 1 }}
        color='default'
      >
        <Toolbar>
          <Box>
            <CombleauLogo />
          </Box>
          <Box flexGrow={1} display='flex' justifyContent='flex-start'>
            <Tooltip
              title={
                <>
                  {config.appearance && (
                    <Typography variant='body2'>
                      {texts.tooltipConfigNameSavedAlready}
                    </Typography>
                  )}
                </>
              }
            >
              <TextField
                {...textFieldStyleProps(texts.inputLabelConfigName)}
                variant='standard'
                sx={{ width: '15em' }}
                error={!config.name && config.appearance === undefined}
                helperText={
                  !config.appearance && !config.name
                    ? texts.formHelperMissingConfigName
                    : ''
                }
                disabled={!!config.appearance}
                value={config.name || ''}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  dispatch(configurationActions.name(event.target.value))
                }
                inputProps={{
                  maxLength: maxTableNameLength(),
                  onKeyDown: (event) => restrictTableNameChars(event),
                }}
              />
            </Tooltip>
          </Box>
          <SetupAppBar s={s} />
        </Toolbar>
      </AppBar>
      <Box sx={{ display: 'flex' }} flexGrow={1} overflow='hidden'>
        <Drawer
          variant='permanent'
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
        >
          <Toolbar />
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='space-between'
            height='stretch'
          >
            <List>
              {[
                {
                  tab: 'Appearance',
                  text: texts.tabLabelAppearance,
                  icon: (
                    <ViewStream
                      color={s === 'Appearance' ? 'primary' : 'inherit'}
                    />
                  ),
                },
                {
                  tab: 'Layout',
                  text: texts.tabLabelLayout,
                  icon: (
                    <Brush color={s === 'Layout' ? 'primary' : 'inherit'} />
                  ),
                },
                {
                  tab: 'Dimensions',
                  text: texts.tabLabelDimensions,
                  icon: (
                    <Abc color={s === 'Dimensions' ? 'primary' : 'inherit'} />
                  ),
                },
              ].map((d, i) => (
                <ListItem key={i} disablePadding>
                  <ListItemButton
                    onClick={() => handleChangeTab(d.tab as STabs)}
                  >
                    <ListItemIcon>{d.icon}</ListItemIcon>
                    <ListItemText primary={d.text} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>

            <Stack direction='column'>
              <List sx={{ justifySelf: 'flex-end' }}>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => handleChangeTab('Help' as STabs)}
                  >
                    <ListItemIcon color={s === 'Help' ? 'primary' : 'inherit'}>
                      <Help />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography>
                          {texts.tabLabelHelp}
                          <br />
                          v. {version}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              </List>
            </Stack>
          </Box>
        </Drawer>
        <Stack flexGrow={1} direction='row' alignItems='stretch' spacing={2}>
          {s === ('Layout' as STabs) && <LayoutSettings />}
          {s === 'Appearance' && <AppearanceSettings />}
          {s === 'Dimensions' && (
            <DimensionSettings
              {...{
                availableSheets,
              }}
            />
          )}
          {s === 'Help' && (
            <Grid container spacing={0} height='100%' overflow='clip'>
              <Stack direction='column' spacing={2} padding={2} width='stretch'>
                <iframe
                  title='support-ticket-form'
                  src='https://share.hsforms.com/1jiDagX0cSZ6XPgKiMRj25w5b1fc'
                  width='100%'
                  height='100%'
                />
              </Stack>
            </Grid>
          )}
        </Stack>
      </Box>

      <AppBar position='sticky' color='default' sx={{ top: 'auto', bottom: 0 }}>
        <Toolbar variant='dense'>
          <Stack
            direction='row'
            spacing={2}
            flexGrow={1}
            justifyContent='flex-end'
            justifySelf='flex-end'
          >
            <Button
              variant='outlined'
              color='primary'
              endIcon={<Close />}
              onClick={handleCancel}
            >
              {texts.buttonTextCancel}
            </Button>
            <ConfigSaveButton />
          </Stack>
        </Toolbar>
      </AppBar>
    </Stack>
  )
}

export default Settings
