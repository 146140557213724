import { TextDictionary } from './../types/languages'

export const de: TextDictionary = {
  // alert texts
  alertTextCommentableWorksheetCommentUid:
    "Konvertiere 'Comment Uid' zu einer Dimension, dann füge sie dem Tooltip hinzu um sie als 'ATTR(Comment Uid)' bereit zu stellen.",
  alertTextCommentableWorksheetDataSource:
    "Füge die von Combleau erstellte Datenbanktabelle entweder als neue Datenquelle oder als Intra-Datenquellen-Join hinzu: 'cb_comments_",
  alertTextCommentableWorksheetDataSourceLive:
    'Die Datenquelle, welche die von Combleau erstelle Kommentartabelle enthält, muss eine Live-Verbindung sein.',
  alertTextCommentableWorksheetSiblingsUid:
    "Konvertiere 'Siblings Uid' zu einer Dimension, dann füge sie dem Tooltip hinzu um sie als 'ATTR(Siblings Uid)' bereit zu stellen.",
  alertTextConfigurationMissing:
    "Combleau wurde noch nicht konfiguriert. Die Option 'Konfigurieren' im Kontextmenü des Tableau Objekts öffnet den Konfigurationsdialog.",
  alertTextFaultyServerConnection:
    'Es konnte keine Verbindung zum Server hergestellt werden. Bitte kontaktieren Sie Ihren Serveradministrator, falls dieses Problem weiter besteht.',
  alertTextUsernameMissing:
    'Erstelle ein berechnetes Feld, welches nur die USERNAME() Funktion enthält. Füge es dann den Reihen oder Zeilen des Username Arbeitsblatts hinzu.',
  alertTextUsernameSheetMissing:
    "Erstelle ein neues Arbeitsblatt und nenne es 'Username'. Füge es dann dem Dashboard hinzu, auf welche du Combleau nutzen möchtest.",
  alertTextMoreMarksThanComments:
    'Mehr Markierungen als Kommentar Identitäten, bei mindestens einer Kommentar Identität in der Selektion gefunden. Zum hinzufügen neuer Kommentare dürfen nur Markierungen ohne bestehende Kommentar Identitäten gesetzt werden, zum Editieren bestehender Kommentare nur solche Markierungen, welche die gleiche Geschwister-Identität inne haben.',
  alertTextMultipleSiblingsUids:
    'Mehrere verschiedene Geschwister-Identitäten in der momentanen Selektion gefunden. Um Kommentare hinzuzufügen oder zu editieren darf maximal eine Geschwister-Identität in der Selektion vorhanden sein.',
  alertTextNonRelatedComments:
    'Mehrere, nicht verwandte, Kommentare in der Selektion gefunden. Zum Editieren wähle entweder einen einzelnen Kommentar ohne Geschwister-Identität aus oder aber mehrere Kommentare mit der gleichen Geschwister-Identität.',
  // alert titles
  alertTitleCommentableWorksheetConfig:
    'Konfigure kommentierbares Arbeitsblatt',
  alertTitleConfigurationMissing: 'Combleau konfigurieren',
  alertTitleFaultyMarkSelection: 'Fehlerhafte Markierung',
  alertTitleFaultyServerConnection: 'Fehlerhafte Serververbindung',
  alertTitleUsernameSheetMissing: 'Nutzer-Arbeitsblatt hinzufügen.',
  // buffers
  bufferDatabaseCheck:
    'Datenbanktest läuft. Gegebenenfalls nötige Änderungen werden durchgeführt.',
  bufferFetchingTableauData: 'Lade Kommentardaten von Tableau.',
  bufferSavingComment:
    'Der Kommentar wird gerade auf der Datenbank gespeichert.',
  bufferSavingConfig: 'Die Konfiguration wird auf der Datenbank gespeichert.',
  bufferTableauCheck: 'Lade Arbeitsblattinformationen vom Dashboard.',
  bufferUsernameCheck: 'Lade Nutzername vom Dashboard.',
  bufferFetchingSavedFields: 'Lade Kommentardaten von Tableau.',
  bufferFetchingSiblings: 'Lade Kommentar-Geschwister-Dimensionen von Tableau.',
  // button texts
  buttonTextAddDropdownOption: 'Option hinzufügen',
  buttonTextAddFormElement: 'Element hinzufügen',
  buttonTextAddRadio: 'Option hinzufügen',
  buttonTextCancel: 'Abbrechen',
  buttonTextDelete: 'Löschen',
  buttonTextDiscard: 'Alle Änderungen verwerfen',
  buttonTextInputElement: 'Eingabe',
  buttonTextLoad: 'Laden',
  buttonTextSave: 'Speichern',
  buttonTextSaveClose: 'Speichern & schließen',
  buttonTextRefresh: 'Aktualisieren',
  buttonTextStyleElement: 'Stil',
  buttonTextThief: 'stehlen',
  buttonTextUpdate: 'Editieren',
  // database table preview
  databaseTablePreviewMetadata: '...Metadaten...',
  databaseTablePreviewInputElements: '...Eingabeelemente...',
  databaseTablePreviewDimensions: '...Dimensionen...',
  // dialog titles
  dialogTitleThief: 'Dieb',
  // dialog contents
  dialogContentThief: 'Konfiguration, von der gestohlen werden soll:',
  // dropdown options
  dropdownOptionDirectionCol: 'Vertikal',
  dropdownOptionDirectionRow: 'Horizontal',
  dropdownOptionTextTypeHeading: 'Überschrift',
  dropdownOptionTextTypeParagraph: 'Fließtext',
  dropdownOptionTextTypeSubtitle: 'Untertitel',
  // form helpers
  formHelperDatabaseColDuplicate:
    'Duplikate von Spaltennamen in Datenbanktabellen sind nicht möglich.',
  formHelperDatabaseColMissing:
    'Ein Name für die Spalte der Datenbank ist notwendig.',
  formHelperDropdownOptionExistsError: 'Diese Option existiert bereits.',
  formHelperFieldRequired: 'Eine Eingabe ist erforderlich.',
  formHelperMissingConfigName: 'Feld erforderlich.',
  formHelperRadioExists: 'Diese Option existiert bereits.',
  // input element types
  inputElementTypeDatepicker: 'Datumsauswahl',
  inputElementTypeRadio: 'Optionsfeld',
  inputElementTypeRating: 'Bewertung',
  // input labels
  inputLabelAddCommentText: 'Kommentar hinzufügen Schaltflächentext',
  inputLabelButtonArrangement: 'Anordnung',
  inputLabelButtonVariant: 'Variante',
  inputLabelColor: 'Farbe',
  inputLabelConfigName: 'Konfigurationsname',
  inputLabelContent: 'Inhalt',
  inputLabelDatabaseColumnName: 'Datenbanktabellen-Spaltenname',
  inputLabelDatepickerPreselectCurrentDate: 'Vorauswahl des momentanen Datums',
  inputLabelDimension: 'Dimension',
  inputLabelDividerChip: 'Chip',
  inputLabelDividerText: 'Text',
  inputLabelEditCommentText: 'Kommentar editieren Schaltflächentext',
  inputLabelElementDependency: 'Abhängigkeit',
  inputLabelElementDependencyRevealingValue: 'Enthüllender Wert',
  inputLabelEnableCommentingOnWorksheet:
    'Kommentieren auf diesem Arbeitsblatt zulassen',
  inputLabelFormLocation: 'Formular anzeigen in',
  inputLabelFormInPopupDialog: 'Neuem Fenster',
  inputLabelFormOnDashboard: 'Tableau Dashboard',
  inputLabelInputElementType: 'Typ des Eingabeelements',
  inputLabelInputLabel: 'Beschriftung',
  inputLabelLevel: 'Ordnung',
  inputLabelLocale: 'Lokalität',
  inputLabelMultiInputElementDirection: 'Ausrichtungsrichtung',
  inputLabelMode: 'Modus',
  inputLabelNewDropdownOption: 'Neue Option',
  inputLabelRatingPrecision: 'Präzision',
  inputLabelRequired: 'Eingabe erfordern',
  inputLabelRequireTableauMarkToComment:
    'Tableau Markierung zum Kommentieren erfordern',
  inputLabelSavedConfigs: 'Gespeicherte Konfigurationen',
  inputLabelSelReq: 'Kommentiere',
  inputLabelStyleElementIdentifier: 'Kennung',
  inputLabelStyleElementType: 'Stilelement-Typen',
  inputLabelTextType: 'Text Variante',
  inputLabelTextAlignment: 'Text Ausrichtung',
  inputLabelLabelPlacement: 'Platzierung der Beschriftung',
  inputLabelTextfieldMultirow: 'Mehrzeilig',
  inputLabelTextfieldFixedNumberOfRows: 'Feste Anzahl an Zeilen',
  inputLabelTextfieldMaximumNumberOfRows: 'Maximale Anzahl an Zeilen',
  inputLabelTextfieldNumberOfRows: 'Zeilenanzahl',
  inputLabelCommentableWorksheets: 'Kommentierbare Arbeitsblätter',
  inputLabelWorksheetDimensions: 'Arbeitsblatt-Dimensionen',
  inputLabelWorksheets: 'Arbeitsblätter',
  none: 'Keine',
  // options
  optionButtonLayoutGrouped: 'Gruppiert',
  optionButtonLayoutSeparate: 'Separat',
  optionButtonVariantContained: 'Solide',
  optionButtonVariantOutlined: 'Umrandet',
  optionButtonVariantText: 'Text',
  // paragraphs
  paragraphEditCommentCheckMarksToEdit:
    'Selektion der zu editierenden Dimensionen.',
  paragraphEditCommentNoCommentUid: 'Zum Editieren Kommentar Id wählen.',
  // section headers
  sectionHeaderDashbordButtons: 'Dashboard Schaltflächen',
  sectionHeaderDefaultValue: 'Standardwert',
  sectionHeaderDialogActionButtons: 'Dialog Schaltflächen',
  sectionHeaderDropdownOptions: 'Optionen',
  sectionHeaderForm: 'Formular',
  sectionHeaderFormElements: 'Formularelemente',
  sectionHeaderFundamental: 'Fundamental',
  sectionHeaderGeneral: 'Generell',
  sectionHeaderMarks: 'Markierungen',
  sectionHeaderPreviewForm: 'Vorschau Formular',
  sectionHeaderPreviewDashboardButtons: 'Vorschau Dashboard-Schaltflächen',
  sectionHeaderPreviewDialogButtons: 'Vorschau Dialog-Schaltflächen',
  sectionHeaderPreviewDatabaseTable: 'Datenbanktabellenspalten',
  sectionHeaderRadioButtons: 'Optionen',
  sectionHeaderWorksheets: 'Arbeitsblätter',
  sectionHeaderWorksheetDimensions: 'Arbeitsblatt Dimensionen',
  selected: 'ausgewählt',
  // snackbars
  snackbarConfigExists:
    'Der Konfigurationsname existiert bereits. Versuchen Sie es erneut mit einem Anderem.',
  snackbarUsernameMissing: 'Nutzername konnte nicht gefunden werden.',
  snackbarDimensionAlreadyCommentedOn:
    'Auf mindestens einer Dimension der gesetzten Markierungen existiert bereits ein Kommentar.',
  snackbarTableauObjectIdMissing:
    'Tableau Objekt Id der Dashboard Extension konnte nicht gefunden werden.',
  // style element types
  styleElementTypeDivider: 'Abgrenzung',
  styleElementTypeText: 'Text',
  // tab labels
  tabLabelAppearance: 'Aussehen',
  tabLabelLayout: 'Formularstruktur',
  tabLabelDimensions: 'Dimensionen',
  tabLabelHelp: 'Hilfe',
  // table heads
  tableHeadCategory: 'Kategorie',
  tableHeadDataType: 'Datentyp',
  tableHeadColumnName: 'Spaltentitel',
  // toogle button options
  toggleButtonOptionModeLight: 'Hell',
  toggleButtonOptionModeDark: 'Dunkel',
  toggleButtonOptionSelNotReq: 'Ohne Datenbezug',
  toggleButtonOptionSelReq: 'Bestimmte Datenpunkte',
  // tooltips
  tooltipAddDropdownOption: 'Enter drücken, um Option hinzuzufügen',
  tooltipAddRadio: 'Enter drücken, um Option hinzuzufügen.',
  tooltipConfigNameSavedAlready:
    'Die Konfiguration wurde bereits unter diesem Namen auf der Datenbank gespeichert.',
  tooltipConfigNameRequired: 'Ein Name für die Konfiguration ist erforderlich.',
  tooltipDatabaseIdentifierImmutablePostSave:
    'Der Spaltenname der Datenbanktabelle kann nicht mehr geändert werden, nachdem die Konfiguration auf der Datenbank gespeichert wurde.',
  tooltipDeleteCommentForAllDimensions:
    'Delete comment for all selected dimensions.',
  tooltipDimensionOptionDisabledSinceInitialized:
    'Diese Dimension kann nicht entfernt werden, da bereits eine Spalte in der Datenbanktabelle für sie angelegt wurde.',
  tooltipDiscardConfigChanges: 'Alle ungespeicherten Änderungen verwerfen.',
  tooltipElementDependencyNoInputElementError:
    'Kein Eingabeelement existiert, somit kann keine Abhängigkeit erstellt werden.',
  tooltipElementDependencyNoOtherInputElementError:
    'Kein anderes Eingabeelement existiert, somit kann keine Abhängigkeit erstellt werden.',
  tooltipFormLayoutConfigErrors: 'Die Formularstruktur enthält Fehler.',
  tooltipNameChangeAfterSave:
    'Die Konfiguration kann nicht mehr umbenannt werden, da sie bereits unter ihrem aktuellen Name auf der Datenbank gespeichert wurde.',
  tooltipNoInputElement: 'Mindestens ein Eingabeelemnt ist erforderlich.',
  tooltipNothingToSave:
    'Es gibt nichts zu speichern. Es wurden keine Änderungen an der momentanen Konfiguration gefunden.',
  tooltipNoChangesToDiscard:
    'Es liegen keine Änderungen vor, welche verworfen werden könnten.',
  tooltipSaveCommentFormStillContainsErrors:
    'Speichern nicht möglich. Das Formular enthält Fehler.',
  tooltipInputFieldWithoutDbColName:
    'Mindestens ein Eingabeelement ohne Datenbanktabellenspaltennamen existiert.',
  tooltipSaveCommentNoValidMarkSelected:
    'Speichern nicht möglich. Keine valide Markierung gewählt.',
  tooltipThiefCurrentLayoutAlreadySaved:
    'Die momentane Konfiguration wurde bereits auf der Datenbank gespeichert. Das Laden einer anderen Formularstruktur könnte zum Verlust existierender Eingabeelemente führen, was nicht erlaubt ist.',
  tooltipThiefCurrentDimensionsAlreadySaved:
    'Die momentane Konfiguration wurde bereits auf der Datenbank gespeichert. Das Laden anderer Dimensionen könnte zum Verlust existierender Dimensionen führen, was nicht erlaubt ist.',
  tooltipWorksheetOptionDisabledSinceInitialized:
    'Dieses Arbeitsblatt kann nicht mehr entfernt werden, da mindestens eine der darauf verfügbaren Dimensionen bereits als Spalte in der Datenbanktabelle erstellt wurde.',
}
