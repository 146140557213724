// hooks
import { useAppSelector } from '../../../../redux/hooks'
import { useState } from 'react'
import { useLanguage } from 'src/hooks/languages'

// mui
import {
  AddCircle,
  Delete,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material'
import {
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  FormHelperText,
  TextField,
  MenuItem,
  ListItem,
  ListItemText,
  List,
  Tooltip,
  Typography,
  Chip,
  Divider,
  Input,
} from '@mui/material'

// components
import { Fragment } from 'react'

// types
import { FC } from 'react'
import { ChangeEvent, KeyboardEvent, MouseEvent } from 'react'
import { DropdownElement, InputElement } from '../../../../types/redux/layout'

// assets
import { checkQuotes } from '../../../../assets/helpers/checkInput'
import { textFieldStyleProps } from '../../../../assets/helpers/mui'

interface DropdownElementSettingsProps {
  idx: number
  handleUpdateInput: (options: Partial<InputElement>) => {
    payload: {
      idx: number
      options: Partial<InputElement>
    }
    type: string
  }
}

const DropdownElementSettings: FC<DropdownElementSettingsProps> = ({
  idx,
  handleUpdateInput,
}) => {
  const texts = useLanguage()

  const element = useAppSelector(
    (state) => state.layout[idx] as DropdownElement
  )

  const [option, setOption] = useState<string>('')

  const addDropdownOption = () => {
    handleUpdateInput({
      options: [...element.options, option],
    })
    setOption('')
  }

  const deleteDropdownOption = (i: number) => {
    let options = element.options!
    options.splice(i, 1)
    handleUpdateInput({
      options,
    })
  }

  const moveDropdownOption = (i: number, d: number) => {
    let options = element.options!.slice()
    options.splice(i + d, 0, options.splice(i, 1)[0])
    handleUpdateInput({
      options,
    })
  }

  let error: boolean = option
    ? !checkQuotes(option) || element.options!.indexOf(option) > -1
    : false

  return (
    <Fragment key={idx}>
      <Divider textAlign='left'>
        <Chip
          label={texts.sectionHeaderDropdownOptions}
          color='default'
          sx={{
            alignSelf: 'center',
            justifySelf: 'center',
            borderRadius: 1,
          }}
        />
      </Divider>
      <FormControl
        variant='standard'
        size='small'
        error={error}
        sx={{
          '& .Mui-error': {
            color: 'warning.main',
          },
          '&  .MuiFormHelperText-root': {
            color: 'warning.main',
          },
          '& .MuiOutlinedInput-root': {
            '&.Mui-error fieldset': {
              borderColor: 'warning.main',
            },
          },
        }}
      >
        <InputLabel htmlFor='option-input'>
          {texts.inputLabelNewDropdownOption}
        </InputLabel>
        <Input
          id='option-input'
          size='small'
          fullWidth
          value={option || ''}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setOption(event.target.value)
          }
          onKeyUp={(event: KeyboardEvent) => {
            if (event.key === 'Enter' && !error && option.length !== 0)
              addDropdownOption()
          }}
          endAdornment={
            <InputAdornment position='end'>
              <Tooltip
                title={
                  <Typography variant='body2'>
                    {texts.tooltipAddDropdownOption}
                  </Typography>
                }
              >
                <span>
                  <IconButton
                    disabled={error || option.length === 0}
                    color='primary'
                    onClick={addDropdownOption}
                    onMouseDown={(event: MouseEvent<HTMLButtonElement>) =>
                      event.preventDefault()
                    }
                    edge='end'
                  >
                    {<AddCircle />}
                  </IconButton>
                </span>
              </Tooltip>
            </InputAdornment>
          }
        />
        <FormHelperText>
          {option && element.options!.indexOf(option) > -1
            ? texts.formHelperDropdownOptionExistsError
            : ''}
        </FormHelperText>
      </FormControl>
      <List dense>
        {element.options!.map((option, i) => (
          <ListItem
            key={i}
            secondaryAction={
              <>
                <IconButton
                  edge='end'
                  color='primary'
                  disabled={i === element.options!.length - 1}
                  onClick={() => moveDropdownOption(i, -1)}
                >
                  <KeyboardArrowUp />
                </IconButton>
                <IconButton
                  edge='end'
                  color='primary'
                  disabled={i === 0}
                  onClick={() => moveDropdownOption(i, 1)}
                >
                  <KeyboardArrowDown />
                </IconButton>
                <IconButton
                  edge='end'
                  color='error'
                  onClick={() => deleteDropdownOption(i)}
                >
                  <Delete />
                </IconButton>
              </>
            }
          >
            <ListItemText primary={option} />
          </ListItem>
        ))}
      </List>
      <Divider textAlign='left'>
        <Chip
          label={texts.sectionHeaderDefaultValue}
          color='default'
          sx={{
            alignSelf: 'center',
            justifySelf: 'center',
            borderRadius: 1,
          }}
        />
      </Divider>
      <TextField
        {...textFieldStyleProps(element.label)}
        select
        value={element.default || ''}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          handleUpdateInput({ default: event.target.value })
        }
      >
        <MenuItem value=''>
          <i>{texts.none}</i>
        </MenuItem>
        {element.options!.map((option, idx) => (
          <MenuItem key={idx} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
    </Fragment>
  )
}

export default DropdownElementSettings
