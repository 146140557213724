// hooks
import { useEffect, useState } from 'react'

// components
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

// mui
import { TextField } from '@mui/material'
import { Suspense } from 'react'
import {
  LocalizationProvider,
  DatePicker,
  MobileDatePicker,
} from '@mui/x-date-pickers'
import '@mui/lab'

// types
import { DatepickerElement, Value } from '../../types/redux/layout'
import { StringIndexableObject } from '../../types/general'
import { FC } from 'react'

// assets
import { lazy } from 'react'
import locales from 'dayjs/locale.json'
import 'dayjs/locale/de'

const lcls = locales.map((locale) => locale.key)

interface DatepickerInputElementProps {
  autoFocus?: boolean
  element: DatepickerElement
  value: Value
  onChange: any
}

const DatepickerInputElement: FC<DatepickerInputElementProps> = ({
  autoFocus,
  element,
  value,
  onChange,
}) => {
  const [datePickerOpen, setDatePickerOpen] = useState<
    StringIndexableObject<boolean>
  >({})
  const [locale, setLocale] = useState('de')

  const datePickerProps = {
    label: element.label,
    value: value || null,
    onChange,
  }

  useEffect(() => {
    let lc = tableau.extensions.environment.language.slice(0, 2)
    lc = lcls.indexOf(lc) > -1 ? lc : 'de'
    lazy(() => import(`dayjs/locale/${lc}.js`))
    setLocale(lc)
  }, [setLocale])

  return (
    <Suspense fallback={<></>}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
        <DatePicker
          {...datePickerProps}
          renderInput={(params) => (
            <TextField
              // focused={expanded === index}
              variant='outlined'
              size='small'
              autoFocus={!!autoFocus}
              {...{ ...params }}
            />
          )}
          onOpen={() =>
            setDatePickerOpen({
              ...datePickerOpen,
              [element.dbCol]: true,
            })
          }
          open={false}
        />
        <MobileDatePicker
          {...datePickerProps}
          open={Boolean(datePickerOpen[element.dbCol])}
          onClose={() =>
            setDatePickerOpen({
              ...datePickerOpen,
              [element.dbCol]: false,
            })
          }
          renderInput={() => <></>}
        />
      </LocalizationProvider>
    </Suspense>
  )
}

export default DatepickerInputElement
