// hooks
import { useLanguage } from 'src/hooks/languages'

// mui
import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Switch,
} from '@mui/material'

// types
import { CheckboxSwitchElement, Value } from '../../types/redux/layout'
import { FC } from 'react'

interface CheckboxSwitchInputElementProps {
  element: CheckboxSwitchElement
  value: Value
  onChange: any
  autoFocus?: boolean
}

const CheckboxSwitchInputElement: FC<CheckboxSwitchInputElementProps> = ({
  element,
  value,
  onChange,
  autoFocus,
}) => {
  const texts = useLanguage()

  const booleanProps: CheckboxProps = {
    color: element.color,
    checked: Boolean(value),
    onChange,
    autoFocus: !!autoFocus,
  }
  return (
    <FormControl error={element.inputErrors.required}>
      <FormGroup key={element.dbCol} sx={{ alignItems: 'flex-start' }}>
        <FormControlLabel
          control={
            element.type === 'Checkbox' ? (
              <Checkbox {...booleanProps} />
            ) : (
              <Switch {...booleanProps} />
            )
          }
          label={element.label}
          labelPlacement={element.labelPlacement}
        />
      </FormGroup>
      <FormHelperText>
        {element.inputErrors.required ? texts.formHelperFieldRequired : ''}
      </FormHelperText>
    </FormControl>
  )
}

export default CheckboxSwitchInputElement
