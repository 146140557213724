// hooks
import { useAppDispatch, useAppSelector } from '../../redux/hooks'

// mui
import { Typography, Divider, Chip } from '@mui/material'

// components
import { ChangeEvent, FC, Fragment } from 'react'
import Textfield from './Textfield'
import Datepicker from './Datepicker'
import Dropdown from './Dropdown'
import Rating from './Rating'
import CheckboxSwitch from './CheckboxSwitch'
import Radiogroup from './Radio'

// types
import {
  CheckboxSwitchElement,
  DatepickerElement,
  DropdownElement,
  InputElement,
  RadiogroupElement,
  RatingElement,
  TextfieldElement,
  Value,
} from '../../types/redux/layout'
import { Dayjs } from 'dayjs'

import { layoutActions } from '../../redux/layout'

interface LayoutViewProps {
  preview?: boolean
}

const LayoutView: FC<LayoutViewProps> = ({ preview }) => {
  const dispatch = useAppDispatch()

  const layout = useAppSelector((state) => state.layout)

  const handleUpdateValue = ({ idx, value }: { idx: number; value: Value }) =>
    dispatch(
      layoutActions.updateValue({
        idx,
        value,
      })
    )

  const handleUpdateValueByEventTargetValue =
    (idx: number) => (event: ChangeEvent<HTMLInputElement>) =>
      handleUpdateValue({ idx, value: event.target.value })

  const handleUpdateValueByEventTargetNumber =
    (idx: number) => (event: ChangeEvent<HTMLInputElement>) =>
      handleUpdateValue({ idx, value: Number(event.target.value) })

  const handleUpdateValueByEventTargetChecked =
    (idx: number) => (event: ChangeEvent<HTMLInputElement>) =>
      handleUpdateValue({ idx, value: event.target.checked })

  let first = true

  const listItems = layout.map((element, idx) => {
    let enabled: boolean = true
    const dependsOn = element.dependsOn
    if (dependsOn > -1) {
      const depEl = layout.find(
        (el, i): el is InputElement => 'dbCol' in el && i === dependsOn
      )
      enabled = element.enablingValue === depEl?.value
    }

    if (enabled && element.visible) {
      if ('dbCol' in element) {
        let inputElement: JSX.Element
        const autoFocus = preview ? false : first
        switch (element.type) {
          case 'Textfield':
            inputElement = (
              <Textfield
                key={idx}
                {...{
                  autoFocus,
                  element: element as TextfieldElement,
                  value: element.value,
                  onChange: handleUpdateValueByEventTargetValue(idx),
                }}
              />
            )
            break
          case 'Datepicker':
            inputElement = (
              <Datepicker
                key={idx}
                {...{
                  autoFocus,
                  element: element as DatepickerElement,
                  value: element.value,
                  onChange: (value: Dayjs | null) => {
                    handleUpdateValue({
                      idx,
                      value,
                    })
                  },
                }}
              />
            )
            break
          case 'Dropdown':
            inputElement = (
              <Dropdown
                key={idx}
                {...{
                  autoFocus,
                  element: element as DropdownElement,
                  value: element.value,
                  onChange: handleUpdateValueByEventTargetValue(idx),
                }}
              />
            )
            break
          case 'Rating':
            inputElement = (
              <Rating
                key={idx}
                {...{
                  autoFocus,
                  element: element as RatingElement,
                  value: element.value,
                  onChange: handleUpdateValueByEventTargetNumber(idx),
                }}
              />
            )
            break
          case 'Checkbox':
          case 'Switch':
            inputElement = (
              <CheckboxSwitch
                key={idx}
                {...{
                  autoFocus,
                  element: element as CheckboxSwitchElement,
                  value: element.value,
                  onChange: handleUpdateValueByEventTargetChecked(idx),
                }}
              />
            )
            break
          case 'Radiogroup':
            inputElement = (
              <Radiogroup
                key={idx}
                {...{
                  autoFocus,
                  element: element as RadiogroupElement,
                  value: element.value,
                  onChange: handleUpdateValueByEventTargetValue(idx),
                }}
              />
            )
            break
        }
        first = false
        return inputElement
      } else
        switch (element.type) {
          case 'Text':
            return element.content?.split('\n').map((str, j) => (
              <Typography
                key={`${idx}-${j}`}
                variant={
                  `${element.textType === 'heading' ? 'h' : element.textType}${
                    element.size
                  }` as
                    | 'h1'
                    | 'h2'
                    | 'h3'
                    | 'h4'
                    | 'h5'
                    | 'h6'
                    | 'inherit'
                    | 'subtitle1'
                    | 'subtitle2'
                    | 'body1'
                    | 'body2'
                    | 'overline'
                    | undefined
                }
                align={element.alignment}
              >
                {str}
              </Typography>
            ))
          case 'Divider':
            return (
              <div key={idx}>
                <Divider textAlign={element.alignment}>
                  {element.text &&
                    (element.chip ? (
                      <Chip label={element.content} color={element.color} />
                    ) : (
                      <Typography>{element.content}</Typography>
                    ))}
                </Divider>
              </div>
            )
        }
    }
    return <Fragment key={idx}></Fragment>
  })

  return <>{listItems}</>
}

export default LayoutView
